import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { getDatabase, ref, onValue, off } from "firebase/database";
import Navbar from "./components/Navbar";
import BeerPriceForm from "./components/BeerPriceForm";
import PricesTable from "./components/PricesTable";
import CheapestPintSection from "./components/CheapestPintSection";
import CheapestPintChart from "./components/CheapestPintChart";
import AveragePintChart from "./components/AveragePintChart";
import CheapestPintMap from "./components/CheapestPintMap";
import PubPage from './components/PubPage';
import MyPints from './account/MyPints';
import TabBar from './components/TabBar';
import { useCookieConsent } from './utils/cookieManagement';
import Disclaimer from './components/disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQs from './components/faqs';
import CheapestPintByCity from './components/CheapestPintByCity';

const AppContent = () => {
  const [selectedBeerType, setSelectedBeerType] = useState(null);
  const [selectedAverageBeerType, setSelectedAverageBeerType] = useState(null);
  const [averagePriceForBeerType, setAveragePriceForBeerType] = useState(0);
  const [prices, setPrices] = useState([]);
  const [pubs, setPubs] = useState([]);
  const [activeTab, setActiveTab] = useState("map");

  const { isConsentGiven, isAllCookiesAccepted } = useCookieConsent();  
  const location = useLocation();

  useEffect(() => {
    const database = getDatabase();
    const pricesRef = ref(database, "beerPrices");

    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      const priceData = data ? Object.values(data) : [];
      setPrices(priceData);

      // Process pubs data
      const pubMap = {};
      priceData.forEach((price) => {
        if (!pubMap[price.pub]) {
          pubMap[price.pub] = {
            ...price,
            pints: [],
            id: price.pub.replace(/\s+/g, '-').toLowerCase(),
          };
        }
        pubMap[price.pub].pints.push({
          beerType: price.beerType,
          price: price.price,
          timestamp: price.timestamp,
        });
      });

      const updatedPubs = Object.values(pubMap).map((pub) => ({
        ...pub,
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lng),
        cheapestPint: pub.price,
        lastRecorded: pub.timestamp,
        beerGarden: pub.beerGarden,
        liveSports: pub.liveSports,
        liveMusic: pub.liveMusic,
      }));

      setPubs(updatedPubs);
    };

    onValue(pricesRef, onDataChange);

    return () => {
      off(pricesRef, onDataChange);
    };
  }, []);

  const shouldShowTabBar = () => {
    return !location.pathname.startsWith('/pub/') && 
           location.pathname !== '/my-pints' &&
           location.pathname !== '/privacy-policy' &&
           location.pathname !== '/faqs';
  };

  return (
    <>
      <Navbar setActiveTab={setActiveTab} />
      <main>
        <div className="flex-grow relative">
          <Routes>
            <Route path="/" element={
              <>
                {activeTab === "map" && <CheapestPintMap />}
                {activeTab === "form" && (
                  <div className="p-4 mb-24 max-w-screen-sm mx-auto">
                    <BeerPriceForm />
                  </div>
                )}
                {activeTab === "recents" && (
                  <div className="p-4 mb-24 max-w-screen-lg mx-auto">
                    <PricesTable prices={Array.isArray(prices) ? prices : []} />
                  </div>
                )}
                {activeTab === "charts" && (
                  <div className="p-4 mb-24 max-w-screen-lg mx-auto">
                    <CheapestPintSection />
                    <CheapestPintByCity />
                    <CheapestPintChart />
                    <AveragePintChart />

                  </div>
                )}
              </>
            } />
            <Route path="/my-pints" element={<MyPints />} />
            <Route path="/pub/:pubId" element={<PubPage pubs={pubs} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<FAQs />} />
          </Routes>
        </div>
        {shouldShowTabBar() && <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />}
      </main>
    </>
  );
};

export default AppContent;