export const getGuinnessRatingLabel = (rating) => {
  const labels = [
    { emoji: '💩', name: 'Absolute Shit' },
    { emoji: '🤢', name: 'Barely Drinkable' },
    { emoji: '👎', name: 'Really Bad' },
    { emoji: '😕', name: 'Below Average' },
    { emoji: '😐', name: 'Meh' },
    { emoji: '🤔', name: 'Passable' },
    { emoji: '👍', name: 'Decent Pint' },
    { emoji: '😊', name: 'Solid Creamer' },
    { emoji: '😍', name: 'Near Perfect' },
    { emoji: '🍻', name: 'Absolute Creamer' }
  ];

  const label = labels[rating - 1];
  return `${rating} - ${label.emoji} ${label.name}`;
};