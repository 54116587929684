// Check if gtag is available
const isGtagAvailable = () => {
    return typeof window !== 'undefined' && window.gtag;
  };
  
  export const sendAnonymousAnalytics = (data) => {
    if (!isGtagAvailable()) {
      console.warn('Google Analytics not available');
      return;
    }
  
    // Generate a random, anonymous ID for this session if it doesn't exist
    let sessionId = localStorage.getItem('anonymousSessionId');
    if (!sessionId) {
      sessionId = Math.random().toString(36).substring(2, 15);
      localStorage.setItem('anonymousSessionId', sessionId);
    }
  
    // Send a custom event to Google Analytics
    window.gtag('event', 'pint_stats', {
      'session_id': sessionId,
      'pint_count': data.pintCount,
      'level': data.level,
      'progress': data.progress
    });
  };