import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { formatDistanceToNow } from "date-fns";

// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

// Check if Firebase is not already initialized
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}

const CheapestPintSection = () => {
  const [cheapestPints, setCheapestPints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWetherspoons, setShowWetherspoons] = useState(true);

  const updateCheapestPints = useCallback((pints) => {
    console.log("Updating cheapest pints", { pints, showWetherspoons });
    const filteredPints = showWetherspoons
      ? pints
      : pints.filter(pint => !pint.pub.toLowerCase().includes('wetherspoon'));

    filteredPints.sort((a, b) => {
      if (a.price === b.price) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }
      return a.price - b.price;
    });

    const uniquePints = [];
    const seenPrices = new Set();
    for (const pint of filteredPints) {
      if (!seenPrices.has(pint.price) && uniquePints.length < 3) {
        uniquePints.push(pint);
        seenPrices.add(pint.price);
      }
    }

    console.log("Setting cheapest pints", uniquePints);
    setCheapestPints(uniquePints);
  }, [showWetherspoons]);

  useEffect(() => {
    console.log("Fetching initial data");
    const fetchCheapestPints = async () => {
      try {
        const snapshot = await firebase
          .database()
          .ref("beerPrices")
          .orderByChild("price")
          .limitToFirst(50)
          .once("value");

        const pints = [];
        snapshot.forEach((childSnapshot) => {
          pints.push(childSnapshot.val());
        });

        console.log("Initial pints fetched", pints);
        updateCheapestPints(pints);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching pint data:", error);
        setLoading(false);
      }
    };

    fetchCheapestPints();

    const beerPricesRef = firebase.database().ref("beerPrices");
    const listener = beerPricesRef.on("child_added", (snapshot) => {
      const newPint = snapshot.val();
      console.log("New pint added", newPint);
      setCheapestPints(prevPints => {
        const updatedPints = [...prevPints, newPint];
        updateCheapestPints(updatedPints);
        return updatedPints;
      });
    });

    return () => beerPricesRef.off("child_added", listener);
  }, [updateCheapestPints]);

  useEffect(() => {
    console.log("showWetherspoons changed", showWetherspoons);
    setLoading(true);
    firebase
      .database()
      .ref("beerPrices")
      .orderByChild("price")
      .limitToFirst(50)
      .once("value")
      .then((snapshot) => {
        const pints = [];
        snapshot.forEach((childSnapshot) => {
          pints.push(childSnapshot.val());
        });
        console.log("Pints refetched after toggle", pints);
        updateCheapestPints(pints);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching pint data:", error);
        setLoading(false);
      });
  }, [showWetherspoons, updateCheapestPints]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Unknown";
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const renderPintRow = (pint, index) => (
    <div key={`${pint.pub}-${pint.beerType}`} className={`flex flex-col sm:flex-row items-start sm:items-center py-3 ${index === 0 ? 'text-base' : 'text-sm'} ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
      <div className="items-center w-full sm:w-1/2 mb-2 sm:mb-0 p-2 flex">
        <div>
          <div className={`font-extrabold ${index === 0 ? 'text-4xl' : 'text-xl'} mr-3`}>£{pint.price.toFixed(2)}</div>
          <div className="font-medium">{pint.beerType}</div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 pl-4 sm:pl-0">
        <a
          className="text-green-600 hover:text-green-800 underline block mb-1"
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(pint.pub)}`}
          target="_blank"
          rel="noreferrer"
        >
          {pint.pub}
        </a>
        <div className={`text-gray-500 ${index === 0 ? 'text-sm' : 'text-xs'}`}>
          {formatTimestamp(pint.timestamp)}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full mb-2 sm:mb-4 p-4 sm:p-4 bg-white rounded-lg border">
      <div className="flex sm:flex-row flex-col sm:gap-8 justify-between mb-4">
      <h2 className="text-2xl font-bold mb-4">Cheapest pint leaderboard</h2>
      <label className="flex items-center cursor-pointer">
    <span className="mr-2 text-sm font-medium text-gray-900">
      {showWetherspoons ? "Include" : "Exclude"} Wetherspoons
    </span>
    <div className="relative">
      <input
        type="checkbox"
        className="sr-only toggle-checkbox"
        checked={showWetherspoons}
        onChange={() => {
          setShowWetherspoons(!showWetherspoons);
          setLoading(true);
        }}
      />
      
      <div className="w-11 h-6 bg-gray-200 rounded-full border border-gray-200 toggle-bg"></div>
      <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
    </div>
  </label>
  </div>
      {loading ? (
        <p>Loading...</p>
      ) : cheapestPints.length > 0 ? (
        <div className="space-y-4">
          {cheapestPints.map((pint, index) => (
            <div key={index} className="bg-gray-100 rounded-lg overflow-hidden">
              {renderPintRow(pint, index)}
            </div>
          ))}
        </div>
      ) : (
        <p>No cheapest pints available</p>
      )}
    </div>
  );
};

export default CheapestPintSection;