import React, { useState } from 'react';
import '../styles/mapkeyStyles.css';

const filterOptions = [
    { id: 'wetherspoons', name: '🥄 Wetherspoons', value: 'show' },
    { id: 'beer-garden', name: '🪴 Beer Gardens', value: 'beer-garden' },
    { id: 'live-music', name: '🎶  Live Music', value: 'live-music' },
    { id: 'live-sport', name: '⚽️ Live Sports', value: 'live-sport' },
  ];

export default function Filters({ onFilterChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    wetherspoons: 'show',
    amenities: [],
  });

  const handleFilterChange = (filterId, value) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (filterId === 'wetherspoons') {
        newFilters.wetherspoons = newFilters.wetherspoons === 'show' ? 'hide' : 'show';
      } else {
        if (newFilters.amenities.includes(value)) {
          newFilters.amenities = newFilters.amenities.filter((item) => item !== value);
        } else {
          newFilters.amenities = [...newFilters.amenities, value];
        }
      }
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  return (
    <div className="leaflet-top leaflet-right" style={{ zIndex: 9999 }}>
      <div className="leaflet-control leaflet-bar bg-white rounded-lg">
        <button
          type="button"
          className="w-full bg-white text-green-700 text-sm font-medium rounded-lg p-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          Pub Filters 
        </button>
        {isOpen && (
          <div className="space-y-2 p-4">
            {filterOptions.map((option) => (
              <div key={option.id} className="flex items-center justify-between">
                <label htmlFor={option.id} className="text-sm pr-8">{option.name}</label>
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    name={option.id}
                    id={option.id}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    checked={
                      option.id === 'wetherspoons'
                        ? selectedFilters.wetherspoons === 'show'
                        : selectedFilters.amenities.includes(option.value)
                    }
                    onChange={() => handleFilterChange(option.id, option.value)}
                  />
                  <label
                    htmlFor={option.id}
                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}