import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { usePintContext } from '../utils/PintContext';
import logo from '../assets/CheapestPint.svg';
import logoMobile from '../assets/CPLogo.png';
import HamburgerMenu from './HamburgerMenu';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ setActiveTab }) => {
  const { reportedPints, currentLevel } = usePintContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmitPintClick = () => {
    setActiveTab("form");
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    setActiveTab("map");
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="bg-blue-100 p-2 lg:px-4">  
        <span className="font-default text-xs mr-2 text-left text-blue-700 flex-auto"><span className="font-bold">Disclaimer:</span> Pint prices listed here are crowdsourced and may not always be accurate or up-to-date. Please confirm with the venue for current pricing. Also, please be as honest as possible when submitting prices, it helps everyone!</span>
      </div>
      
      <nav className="container mx-auto max-w-7xl p-4 sm:px-4 sm:py-8">
        <div className="container mx-auto flex flex-row justify-between items-center">
          <div className="flex items-center">
            <Link to="/" onClick={handleLogoClick}>
              <img src={isMobile ? logoMobile : logo} alt="Cheapest pint logo" className="h-8 sm:h-12 mr-2" />
            </Link>
          </div>
          
          <div className="flex items-center space-x-1">
            <button 
              onClick={handleSubmitPintClick} 
              className="inline-flex items-center px-3 sm:px-6 py-2 sm:py-4 bg-green-100 rounded-lg text-green-700 font-semibold text-sm text-center hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-300" 
              aria-label="Submit a pint"
            >
              {isMobile ? 'Submit 🍺' : 'Submit a pint 🍺'}
            </button>
            <button
              onClick={toggleMenu}
              className="ml-2 sm:ml-4 text-gray-600 hover:text-gray-800 focus:outline-none"
              aria-label="Open menu"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <HamburgerMenu 
        isOpen={isMenuOpen} 
        onClose={toggleMenu} 
        reportedPints={reportedPints}
        currentLevel={currentLevel}
      />
    </>
  );
}

export default Navbar;