import React from 'react';

const TabBar = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { name: 'Map', icon: '🗺️', id: 'map' },
    { name: 'Submit a pint', icon: '🍺', id: 'form' },
    { name: 'Recent pints', icon: '📄', id: 'recents' },
    { name: 'Stats', icon: '📈', id: 'charts' },

  ]

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full max-w-full grid-cols-4 mx-auto font-medium">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            type="button"
            onClick={() => setActiveTab(tab.id)}
            className={`inline-flex flex-col items-center justify-center px-2 group relative ${
              activeTab === tab.id ? 'text-green-600' : 'text-gray-500 dark:text-gray-400'
            }`}
          >
            <div className={`absolute top-0 left-0 right-0 h-0.5 bg-green-500 transition-all duration-300 ease-in-out ${
              activeTab === tab.id ? 'opacity-100' : 'opacity-0'
            }`}></div>
            <span className={`material-icons mb-2 ${activeTab === tab.id ? 'text-green-600' : 'text-gray-500 dark:text-gray-400'}`}>
              {tab.icon}
            </span>
            <span className={`text-xs ${activeTab === tab.id ? 'text-green-600' : 'text-gray-500 dark:text-gray-400'}`}>
              {tab.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabBar;