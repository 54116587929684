import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePintContext } from '../utils/PintContext';
import { getGuinnessRatingLabel } from '../utils/guinnessRating';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { sendAnonymousAnalytics } from '../utils/analytics';


const createGoogleMapsLink = (pubName) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(pubName)}`;
  };

  const getLevelInfo = (pintCount) => {
    const levels = [
      { threshold: 0, name: "1 - What's beer? 🤷‍♂️" },
      { threshold: 3, name: "2 - Beginner brew 🟩" },
      { threshold: 5, name: "3 - Pint enthusiast 🍺" },
      { threshold: 10, name: "4 - Pub regular 🍻" },
      { threshold: 20, name: "5 - Local legend 🐉" },
      { threshold: 30, name: "6 - Pints are life 🛟 ✨" }
    ];
    
    let currentLevel = levels[0];
    let nextLevel = levels[1];
    
    for (let i = 0; i < levels.length - 1; i++) {
      if (pintCount >= levels[i].threshold && pintCount < levels[i + 1].threshold) {
        currentLevel = levels[i];
        nextLevel = levels[i + 1];
        break;
      }
    }
    
    // Handle the case when the user is at the highest level
    if (pintCount >= levels[levels.length - 1].threshold) {
      currentLevel = levels[levels.length - 1];
      nextLevel = currentLevel;
    }
    
    let progress;
    if (currentLevel === nextLevel) {
      progress = 100;
    } else {
      const rangeSize = nextLevel.threshold - currentLevel.threshold;
      const progressInRange = pintCount - currentLevel.threshold;
      progress = (progressInRange / rangeSize) * 100;
    }
    
    return { currentLevel, nextLevel, progress };
  };

const MyPints = () => {
  const [reportedPints, setReportedPints] = useState([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [levelInfo, setLevelInfo] = useState({ currentLevel: { name: "What's beer?", threshold: 0 }, nextLevel: { name: "Beginner brew", threshold: 1 }, progress: 0 });
  const { updateReportedPints } = usePintContext();
  const navigate = useNavigate();

  const handleSubmitPintClick = (e) => {
    e.preventDefault();
    navigate('/#beerForm', { state: { scrollToForm: true } });
  };


  


  useEffect(() => {
    loadPints();
  }, []);

  const loadPints = () => {
    const storedPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]');
    const sortedPints = storedPints.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setReportedPints(sortedPints);
    updateReportedPints(sortedPints.length);
    const newLevelInfo = getLevelInfo(sortedPints.length);
    setLevelInfo(newLevelInfo);

    // Send anonymous analytics
    sendAnonymousAnalytics({
      pintCount: sortedPints.length,
      level: newLevelInfo.currentLevel.name,
      progress: newLevelInfo.progress
    });
  };

  const handleClearAllPints = () => {
    if (isConfirming) {
      localStorage.removeItem('reportedPintDetails');
      setReportedPints([]);
      updateReportedPints(0);
      setLevelInfo(getLevelInfo(0));
      setIsConfirming(false);
    } else {
      setIsConfirming(true);
    }
  };

  return (
    // Back and levelinfo
<div className="container mx-auto max-w-7xl p-4">
  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
    <Link to="/" className="mb-4 sm:mb-8 inline-block underline text-green-600 hover:text-green-900">
      ← Back
    </Link>
    <div className="mb-6 border border-gray-200 rounded-lg p-4 w-full sm:w-auto">
      <h2 className="text-xl font-semibold mb-2">Level: {levelInfo.currentLevel.name}</h2>
      <div className="w-full sm:w-64 bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-green-600 h-2.5 rounded-full" 
          style={{ width: `${Math.min(100, Math.max(0, levelInfo.progress))}%` }}
        ></div>
      </div>
      <p className="text-sm mt-1">
        {reportedPints.length} / {levelInfo.nextLevel.threshold} pints to "{levelInfo.nextLevel.name}"
      </p>
    </div>
  </div>



<div className="border border-gray-200 p-4 rounded-lg">
      <div className="flex justify-between">
        <h1 className="text-xl sm:text-2xl font-bold mb-4">My Reported Pints</h1>
        {reportedPints.length > 0 && (
            <button
                onClick={handleClearAllPints}
                className={`border ${isConfirming ? 'border-red-700 bg-red-50' : 'border-red-700'} hover:bg-red-50 text-red-500 font-bold py-2 px-4 text-sm rounded mb-4`}
            >
                {isConfirming ? "Confirm delete all pints?" : "Delete all Pints"}
            </button>
            )}
      </div>
      {reportedPints.length === 0 ? (
        <>
        <p>You haven't reported any pints yet.</p>
        <Link to="/#beerForm" onClick={handleSubmitPintClick} className="mt-4 inline-block underline text-green-600 hover:text-green-900">
        Submit a pint →
      </Link>
      </>
      ) : (
        <ul className="space-y-4">
          {reportedPints.map((pint, index) => {
            const isGuinness = pint.beerType.toLowerCase() === 'guinness';
            const hasGuinnessRating = pint.guinnessRating !== undefined && pint.guinnessRating !== null;
            return (
              <li key={index} className="bg-white shadow rounded-lg p-4">
                <p className="font-bold">{pint.beerType}</p>
                <p className="text-2xl font-bold">
                  £{pint.price.toFixed(2)}
                  {isGuinness && hasGuinnessRating && (
                    <span className="align-middle bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2">
                      {getGuinnessRatingLabel(pint.guinnessRating)}
                    </span>
                  )}
                </p>
                <a 
                  href={createGoogleMapsLink(pint.pub)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-600 hover:text-green-800"
                >
                  {pint.pub}
                </a>
                <p className="text-sm text-gray-500">
                  Reported {formatDistanceToNow(parseISO(pint.timestamp))} ago
                </p>
              </li>
            );
          })}
        </ul>
      )}
      <div className="flex items-center p-4 mt-8 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Disclaimer!</span>
        <div>
          <span className="font-medium">No account required:</span> Pint contributions are saved locally in your browser which is why you don't need an account. If you clear your browser history, use in incognito or another device, these will be lost.
        </div>
      </div>
    </div>
    </div>
  );
};

export default MyPints;