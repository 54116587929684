// beerTypes.js
const beerTypes = [
  { value: "Guinness", label: "Guinness" },
  { value: "San Miguel", label: "San Miguel" },
  { value: "Magners", label: "Magners" },
  { value: "Stella Artois", label: "Stella Artois" },
  { value: "Stella Artois Unfiltered", label: "Stella Artois Unfiltered" },
  { value: "Peroni", label: "Peroni" },
  { value: "Heineken", label: "Heineken" },
  { value: "Corona", label: "Corona" },
  { value: "Kopparberg", label: "Kopparberg" },
  { value: "Strongbow", label: "Strongbow" },
  { value: "Bulmers", label: "Bulmers" },
  { value: "Budweiser", label: "Budweiser" },
  { value: "Old Speckled Hen", label: "Old Speckled Hen" },
  { value: "Thatchers", label: "Thatchers" },
  { value: "Camden Hells", label: "Camden Hells" },
  { value: "Camden Pale", label: "Camden Pale" },
  { value: "Carling", label: "Carling" },
  { value: "Strongbow Dark Fruits", label: "Strongbow Dark Fruits" },
  { value: "Amstel", label: "Amstel" },
  { value: "Carlsberg", label: "Carlsberg" },
  { value: "Tetley's Brewery", label: "Tetley's Brewery" },
  { value: "Grolsch", label: "Grolsch" },
  { value: "Kronenbourg 1664", label: "Kronenbourg 1664" },
  { value: "Marston's", label: "Marston's" },
  { value: "Hobgoblin", label: "Hobgoblin" },
  { value: "London Pride", label: "London Pride" },
  { value: "Tiger Beer", label: "Tiger Beer" },
  { value: "Estrella Damm", label: "Estrella Damm" },
  { value: "Beck's", label: "Beck's" },
  { value: "John Smith's", label: "John Smith's" },
  { value: "Birra Moretti", label: "Birra Moretti" },
  { value: "Murphy's", label: "Murphy's" },
  { value: "Woodpecker Cider", label: "Woodpecker Cider" },
  { value: "Coors Light", label: "Coors Light" },
  { value: "Corona Extra", label: "Corona Extra" },
  { value: "Old Mout", label: "Old Mout" },
  { value: "Foster's", label: "Foster's" },
  { value: "Sol", label: "Sol" },
  { value: "Boddingtons", label: "Boddingtons" },
  { value: "Marston's Pedigree", label: "Marston's Pedigree" },
  { value: "Desperados", label: "Desperados" },
  { value: "Red Stripe", label: "Red Stripe" },
  { value: "Newcastle Brown Ale", label: "Newcastle Brown Ale" },
  { value: "Doom Bar", label: "Doom Bar" },
  { value: "Leffe", label: "Leffe" },
  { value: "Old Peculier", label: "Old Peculier" },
  { value: "Chef & Brewer", label: "Chef & Brewer" },
  { value: "Greene King IPA", label: "Greene King IPA" },
  { value: "Shandy Bass", label: "Shandy Bass" },
  { value: "Black Sheep", label: "Black Sheep" },
  { value: "Carling Cider", label: "Carling Cider" },
  { value: "Beavertown - Neck Oil", label: "Beavertown - Neck Oil" },
  { value: "Beavertown - Gamma Ray", label: "Beavertown - Gamma Ray" },
  { value: "Rekorderlig", label: "Rekorderlig" },
  { value: "Fuller's", label: "Fuller's" },
  { value: "Bass", label: "Bass" },
  { value: "Banks Amber Bitter", label: "Banks Amber Bitter" },
  { value: "Old Crafty Hen", label: "Old Crafty Hen" },
  { value: "Efes", label: "Efes" },
  { value: "Skol", label: "Skol" },
  { value: "Hoegaarden", label: "Hoegaarden" },
  { value: "Thwaites", label: "Thwaites" },
  { value: "Worthington's", label: "Worthington's" },
  { value: "Bavaria", label: "Bavaria" },
  { value: "Abbot Ale", label: "Abbot Ale" },
  { value: "Bud Light", label: "Bud Light" },
  { value: "Bishops Finger", label: "Bishops Finger" },
  { value: "Henry Westons Vintage Cider", label: "Henry Westons Vintage Cider" },
  { value: "Tennent's", label: "Tennent's" },
  { value: "Merrydown", label: "Merrydown" },
  { value: "Asahi", label: "Asahi" },
  { value: "Somersby", label: "Somersby" },
  { value: "BrewDog Lost Lager", label: "BrewDog Lost Lager" },
  { value: "BrewDog Punk IPA", label: "BrewDog Punk IPA" },
  { value: "BrewDog Hazy Jane", label: "BrewDog Hazy Jane" },
  { value: "Marstons", label: "Marstons" },
  { value: "Mcewans", label: "Mcewans" },
  { value: "Whitstable Bay", label: "Whitstable Bay" },
  { value: "Cobra Indian", label: "Cobra Indian" },
  { value: "Erdinger", label: "Erdinger" },
  { value: "Aspall", label: "Aspall" },
  { value: "Madri", label: "Madri" },
  { value: "Abbott", label: "Abbott" },
  { value: "Oakham", label: "Oakham" },
  { value: "Rattler", label: "Rattler" },
  { value: "Sheppys", label: "Sheppys" },
  { value: "Woodforde", label: "Woodforde" },
  { value: "Warsteiner", label: "Warsteiner" },
  { value: "Holsten", label: "Holsten" },
  { value: "Inch's", label: "Inch's" },
  { value: "Brothers Cider", label: "Brothers Cider" },
  { value: "Staropramen", label: "Staropramen" },
  { value: "Old Golden Hen", label: "Old Golden Hen" },
  { value: "St Austell", label: "St Austell" },
  { value: "Singha", label: "Singha" },
  { value: "Henry Westons", label: "Henry Westons" },
  { value: "Savanna Dry Cider", label: "Savanna Dry Cider" },
  { value: "Oranjeboom", label: "Oranjeboom" },
  { value: "Pilsner Urquell", label: "Pilsner Urquell" },
  { value: "Caffrey's", label: "Caffrey's" },
  { value: "Blackthorn", label: "Blackthorn" },
  { value: "Spitfire Kentish Ale", label: "Spitfire Kentish Ale" },
  { value: "Bombardier", label: "Bombardier" },
  { value: "Wadworth", label: "Wadworth" },
  { value: "Westons", label: "Westons" },
  { value: "Harp", label: "Harp" },
  { value: "Badger Ales", label: "Badger Ales" },
  { value: "Tyskie", label: "Tyskie" },
  { value: "Special Brew", label: "Special Brew" },
  { value: "Duvel", label: "Duvel" },
  { value: "Tanglefoot", label: "Tanglefoot" },
  { value: "Brakspear Triple", label: "Brakspear Triple" },
  { value: "McEwan's", label: "McEwan's" },
  { value: "Wainwright", label: "Wainwright" },
  { value: "Courage Directors", label: "Courage Directors" },
  { value: "Shepherd Neame", label: "Shepherd Neame" },
  { value: "Brahma", label: "Brahma" },
  { value: "Coopers", label: "Coopers" },
  { value: "Hop House 13", label: "Hop House 13" },
  { value: "Ruddles County", label: "Ruddles County" },
  { value: "Ruddles Best", label: "Ruddles Best" },
  { value: "Budějovický Budvar", label: "Budějovický Budvar" },
  { value: "Budvar Original Lager", label: "Budvar Original Lager" },
  { value: "Brains", label: "Brains" },
  { value: "Innis & Gunn", label: "Innis & Gunn" },
  { value: "Wychwood", label: "Wychwood" },
  { value: "Samuel Adams", label: "Samuel Adams" },
  { value: "Chang", label: "Chang" },
  { value: "TsingTao", label: "TsingTao" },
  { value: "Otter Ale", label: "Otter Ale" },
  { value: "Bells", label: "Bells" },
  { value: "Cruzcampo", label: "Cruzcampo" },
  { value: "Tuborg", label: "Tuborg" },
  { value: "K Cider", label: "K Cider" },
  { value: "Lindemans", label: "Lindemans" },
  { value: "Chimay", label: "Chimay" },
  { value: "Goose Island", label: "Goose Island" },
  { value: "Frosty Jack", label: "Frosty Jack" },
  { value: "Gaymer", label: "Gaymer" },
  { value: "Miller Genuine Draft", label: "Miller Genuine Draft" },
  { value: "Bath Ales", label: "Bath Ales" },
  { value: "Old Rosie", label: "Old Rosie" },
  { value: "Blue Boar Lager", label: "Blue Boar Lager" },
  { value: "Hawkstone", label: "Hawkstone" },
  { value: "Soho Lager", label: "Soho Lager" },
  { value: "Deuchars", label: "Deuchars" },
  { value: "Prava", label: "Prava" },
  { value: "First Call", label: "First Call" },
  { value: "Rossi", label: "Rossi" },
  { value: "Rousse", label: "Rousse" },
  { value: "Albino Squid Assassin", label: "Albino Squid Assassin" },
  { value: "Witbier", label: "Witbier" },
  { value: "The Ghost", label: "The Ghost" },
  { value: "Cowcatcher APA", label: "Cowcatcher APA" },
  { value: "Alkoholfrei", label: "Alkoholfrei" },
  { value: "Ripper", label: "Ripper" },
  { value: "Dublin Porter", label: "Dublin Porter" },
  { value: "Ruby Rooster", label: "Ruby Rooster" },
  { value: "House Stout", label: "House Stout" },
  { value: "Draught Pale Ale", label: "Draught Pale Ale" },
  { value: "1664", label: "1664" },
  { value: "Nagori Yuki", label: "Nagori Yuki" },
  { value: "Psychedelic IPA", label: "Psychedelic IPA" },
  { value: "Notting Hill Blonde", label: "Notting Hill Blonde" },
  { value: "Herbal Ale", label: "Herbal Ale" },
  { value: "Mein Alkoholfrei", label: "Mein Alkoholfrei" },
  { value: "Green Tea IPA", label: "Green Tea IPA" },
  { value: "Copeland", label: "Copeland" },
  { value: "On the Cloud", label: "On the Cloud" },
  { value: "Daydream", label: "Daydream" },
  { value: "East End Mild", label: "East End Mild" },
  { value: "BoHo Bohemian Lager", label: "BoHo Bohemian Lager" },
  { value: "Santa Gose F It All", label: "Santa Gose F It All" },
  { value: "Fruli", label: "Fruli" },
  { value: "Double Brew Lager", label: "Double Brew Lager" },
  { value: "Premium Black", label: "Premium Black" },
  { value: "71 Lager", label: "71 Lager" },
  { value: "1555", label: "1555" },
  { value: "Electric India", label: "Electric India" },
  { value: "Nanny State", label: "Nanny State" },
  { value: "Elvis Juice", label: "Elvis Juice" },
  { value: "Cosmic Crush Cherry Sour", label: "Cosmic Crush Cherry Sour" },
  { value: "RIS", label: "RIS" },
  { value: "Soelaas", label: "Soelaas" },
  { value: "Watercress Warrior", label: "Watercress Warrior" },
  { value: "Golden Pride", label: "Golden Pride" },
  { value: "Pumpkin Party", label: "Pumpkin Party" },
  { value: "Midway IPA", label: "Midway IPA" },
  { value: "Remix IPA", label: "Remix IPA" },
  { value: "Golden Ale", label: "Golden Ale" },
  { value: "Best Bitter", label: "Best Bitter" },
  { value: "Nest White Ale", label: "Nest White Ale" },
  { value: "Red Ale", label: "Red Ale" },
  { value: "Dexter Milk Stout", label: "Dexter Milk Stout" },
  { value: "Mangolicious", label: "Mangolicious" },
  { value: "Dark Arts", label: "Dark Arts" },
  { value: "Merlin's Gold", label: "Merlin's Gold" },
  { value: "Workie Ticket", label: "Workie Ticket" },
  { value: "Sugura Bay Imperial IPA", label: "Sugura Bay Imperial IPA" },
  { value: "Kaga Roasted Tea Stout", label: "Kaga Roasted Tea Stout" },
  { value: "Beardo", label: "Beardo" },
  { value: "Liquid Mistress", label: "Liquid Mistress" },
  { value: "Splendid Tackle", label: "Splendid Tackle" },
  { value: "496", label: "496" },
  { value: "Jazzberry", label: "Jazzberry" },
  { value: "Bourgogne des Flandres", label: "Bourgogne des Flandres" },
  { value: "Iceberg", label: "Iceberg" },
  { value: "Tripel", label: "Tripel" },
  { value: "Master IPA", label: "Master IPA" },
  { value: "Waggledance", label: "Waggledance" },
  { value: "Black Wych", label: "Black Wych" },
  { value: "Orange Wedge", label: "Orange Wedge" },
  { value: "Golden Glory", label: "Golden Glory" },
  { value: "Gem", label: "Gem" },
  { value: "Barnsey", label: "Barnsey" },
  { value: "Chocs Away", label: "Chocs Away" },
  { value: "Puur", label: "Puur" },
  { value: "Himalayan Red Rye Ale", label: "Himalayan Red Rye Ale" },
  { value: "Black Cloak", label: "Black Cloak" },
  { value: "Belgian White Ale", label: "Belgian White Ale" },
  { value: "DDH IPA Number Two", label: "DDH IPA Number Two" },
  { value: "BrewDog - Punk IPA", label: "BrewDog - Punk IPA" },
  { value: "Radio Zombie Phone In", label: "Radio Zombie Phone In" },
  { value: "Brixton Atlantic Pale Ale", label: "Brixton Atlantic Pale Ale" },
  { value: "Brixton Coldharbour Lager", label: "Brixton Coldharbour Lager" },
  { value: "Brixton Electric West Coast", label: "Brixton Electric West Coast" },
  { value: "Brixton Low Voltage", label: "Brixton Low Voltage" },
  { value: "Brixton Reliance Pale Ale", label: "Brixton Reliance Pale Ale" },
  { value: "Beavertown - Bloody 'Ell", label: "Beavertown - Bloody 'Ell" },
  { value: "Beavertown - Lupuloid", label: "Beavertown - Lupuloid" },
  { value: "BrewDog - Dead Pony Club", label: "BrewDog - Dead Pony Club" },
  { value: "BrewDog - Wingman", label: "BrewDog - Wingman" },
  { value: "BrewDog - Vagabond", label: "BrewDog - Vagabond" },
  { value: "Fuller's - ESB", label: "Fuller's - ESB" },
  { value: "Fuller's - London Porter", label: "Fuller's - London Porter" },
  { value: "Tiny Rebel - Cwtch", label: "Tiny Rebel - Cwtch" },
  { value: "Tiny Rebel - Clwb Tropicana", label: "Tiny Rebel - Clwb Tropicana" },
  { value: "Goose Island - IPA", label: "Goose Island - IPA" },
  { value: "Thornbridge - Lukas", label: "Thornbridge - Lukas" },
  { value: "Cloudwater - IPA", label: "Cloudwater - IPA" },
  { value: "Sierra Nevada - Pale Ale", label: "Sierra Nevada - Pale Ale" },
  { value: "Sierra Nevada - Torpedo", label: "Sierra Nevada - Torpedo" },
  { value: "Lagunitas - IPA", label: "Lagunitas - IPA" },
  { value: "Samuel Smith's - Oatmeal Stout", label: "Samuel Smith's - Oatmeal Stout" },
  { value: "Samuel Smith's - Nut Brown Ale", label: "Samuel Smith's - Nut Brown Ale" },
  { value: "Theakston - Old Peculier", label: "Theakston - Old Peculier" },
  { value: "Theakston - Best Bitter", label: "Theakston - Best Bitter" },
  { value: "Young's - Special", label: "Young's - Special" },
  { value: "Young's - London Stout", label: "Young's - London Stout" },
  { value: "Five Points - Pale", label: "Five Points - Pale" },
  { value: "Five Points - XPA", label: "Five Points - XPA" },
  { value: "Bristol Beer Factory - Milk Stout", label: "Bristol Beer Factory - Milk Stout" },
  { value: "Moor Beer - Hoppiness", label: "Moor Beer - Hoppiness" },
  { value: "Moor Beer - Nor'Hop", label: "Moor Beer - Nor'Hop" },
  { value: "Dark Star - Hophead", label: "Dark Star - Hophead" },
  { value: "Dark Star - APA", label: "Dark Star - APA" },
  { value: "Oakham - Citra", label: "Oakham - Citra" },
  { value: "Oakham - Inferno", label: "Oakham - Inferno" },
  { value: "Redchurch - Brick Lane Lager", label: "Redchurch - Brick Lane Lager" },
  { value: "Redchurch - Shoreditch Blonde", label: "Redchurch - Shoreditch Blonde" },
  { value: "Magic Rock - High Wire", label: "Magic Rock - High Wire" },
  { value: "Magic Rock - Cannonball", label: "Magic Rock - Cannonball" },
  { value: "Wild Beer - Pogo", label: "Wild Beer - Pogo" },
  { value: "Wild Beer - Millionaire", label: "Wild Beer - Millionaire" },
  { value: "Purity - Mad Goose", label: "Purity - Mad Goose" },
  { value: "Purity - Pure UBU", label: "Purity - Pure UBU" },
  { value: "Buxton - Axe Edge", label: "Buxton - Axe Edge" },
  { value: "Buxton - Trolltunga", label: "Buxton - Trolltunga" },
  { value: "Northern Monk - Eternal", label: "Northern Monk - Eternal" },
  { value: "Northern Monk - New World IPA", label: "Northern Monk - New World IPA" },
  { value: "Meantime - London Pale Ale", label: "Meantime - London Pale Ale" },
  { value: "Meantime - London Lager", label: "Meantime - London Lager" },
  { value: "Camden - Gentleman’s Wit", label: "Camden - Gentleman’s Wit" },
  { value: "Camden - Ink", label: "Camden - Ink" },
  { value: "Fourpure - Juicebox", label: "Fourpure - Juicebox" },
  { value: "Fourpure - Easy Peeler", label: "Fourpure - Easy Peeler" },
  { value: "Kirkstall - Pale Ale", label: "Kirkstall - Pale Ale" },
  { value: "Kirkstall - Dissolution IPA", label: "Kirkstall - Dissolution IPA" },
  { value: "Saltaire - Blonde", label: "Saltaire - Blonde" },
  { value: "Saltaire - Triple Chocoholic", label: "Saltaire - Triple Chocoholic" },
  { value: "Titanic - Plum Porter", label: "Titanic - Plum Porter" },
  { value: "Titanic - Stout", label: "Titanic - Stout" },
  { value: "Rooster’s - Yankee", label: "Rooster’s - Yankee" },
  { value: "Rooster’s - Baby-Faced Assassin", label: "Rooster’s - Baby-Faced Assassin" },
  { value: "Black Sheep - Riggwelter", label: "Black Sheep - Riggwelter" },
  { value: "Black Sheep - Best Bitter", label: "Black Sheep - Best Bitter" },
  { value: "Harvey's Sussex Best Bitter", label: "Harvey's Sussex Best Bitter" },
  { value: "Thornbridge Jaipur", label: "Thornbridge Jaipur" },
  { value: "Thornbridge Kipling", label: "Thornbridge Kipling" },
  { value: "Fuller's London Porter", label: "Fuller's London Porter" },
  { value: "Fuller's ESB", label: "Fuller's ESB" },
  { value: "Theakston Old Peculier", label: "Theakston Old Peculier" },
  { value: "Theakston Best Bitter", label: "Theakston Best Bitter" },
  { value: "Greene King Abbot Ale", label: "Greene King Abbot Ale" },
  { value: "Greene King St Edmunds", label: "Greene King St Edmunds" },
  { value: "Samuel Smith's Taddy Porter", label: "Samuel Smith's Taddy Porter" },
  { value: "Samuel Smith's Organic", label: "Samuel Smith's Organic" },
  { value: "Marston's Old Empire", label: "Marston's Old Empire" },
  { value: "Marston's Saddle Tank", label: "Marston's Saddle Tank" },
  { value: "Badger Tanglefoot", label: "Badger Tanglefoot" },
  { value: "Badger The Fursty Ferret", label: "Badger The Fursty Ferret" },
  { value: "Badger Golden Champion", label: "Badger Golden Champion" },
  { value: "Timothy Taylor's Landlord", label: "Timothy Taylor's Landlord" },
  { value: "Timothy Taylor's Boltmaker", label: "Timothy Taylor's Boltmaker" },
  { value: "St Austell Tribute", label: "St Austell Tribute" },
  { value: "St Austell Proper Job", label: "St Austell Proper Job" },
  { value: "Robinsons Trooper", label: "Robinsons Trooper" },
  { value: "Robinsons Dizzy Blonde", label: "Robinsons Dizzy Blonde" },
  { value: "Adnams Broadside", label: "Adnams Broadside" },
  { value: "Adnams Ghost Ship", label: "Adnams Ghost Ship" },
  { value: "Wychwood Hobgoblin Gold", label: "Wychwood Hobgoblin Gold" },
  { value: "Wychwood Black Wych", label: "Wychwood Black Wych" },
  { value: "Wychwood King Goblin", label: "Wychwood King Goblin" },
  { value: "Williams Brothers - March of the Penguins", label: "Williams Brothers - March of the Penguins" },
  { value: "Williams Brothers - Joker IPA", label: "Williams Brothers - Joker IPA" },
  { value: "Williams Brothers - Birds and Bees", label: "Williams Brothers - Birds and Bees" },
  { value: "Brew York - Tonkoko", label: "Brew York - Tonkoko" },
  { value: "Brew York - Big Eagle", label: "Brew York - Big Eagle" },
  { value: "Brew York - Goose Willis", label: "Brew York - Goose Willis" },
  { value: "Hawkshead - Windermere Pale", label: "Hawkshead - Windermere Pale" },
  { value: "Hawkshead - Brodie's Prime", label: "Hawkshead - Brodie's Prime" },
  { value: "Hawkshead - Red", label: "Hawkshead - Red" },
  { value: "Tiny Rebel - Cwtch", label: "Tiny Rebel - Cwtch" },
  { value: "Tiny Rebel - Clwb Tropicana", label: "Tiny Rebel - Clwb Tropicana" },
  { value: "Tiny Rebel - Cali Pale", label: "Tiny Rebel" },
  { value: "Northern Monk - Eternal", label: "Northern Monk - Eternal" },
  { value: "Northern Monk - Faith", label: "Northern Monk - Faith" },
  { value: "Magic Rock - High Wire", label: "Magic Rock - High Wire" },
  { value: "Magic Rock - Cannonball", label: "Magic Rock - Cannonball" },
  { value: "Siren Craft Brew - Broken Dream", label: "Siren Craft Brew - Broken Dream" },
  { value: "Siren Craft Brew - Soundwave", label: "Siren Craft Brew - Soundwave" },
  { value: "The Kernel Brewery - Table Beer", label: "The Kernel Brewery - Table Beer" },
  { value: "The Kernel Brewery - Export India Porter", label: "The Kernel Brewery - Export India Porter" },
  { value: "Wild Beer Co - Millionaire", label: "Wild Beer Co - Millionaire" },
  { value: "Wild Beer Co - Pogo", label: "Wild Beer Co - Pogo" },
  { value: "Fourpure Brewing Co - Easy Peeler", label: "Fourpure Brewing Co - Easy Peeler" },
  { value: "Fourpure Brewing Co - Shapeshifter", label: "Fourpure Brewing Co - Shapeshifter" },
  { value: "Redchurch Brewery - Shoreditch Blonde", label: "Redchurch Brewery - Shoreditch Blonde" },
  { value: "Redchurch Brewery - Bethnal Pale Ale", label: "Redchurch Brewery - Bethnal Pale Ale" },
  { value: "Pressure Drop Brewing - Pale Fire", label: "Pressure Drop Brewing - Pale Fire" },
  { value: "Pressure Drop Brewing - Stokey Brown", label: "Pressure Drop Brewing - Stokey Brown" },
  { value: "Brew By Numbers - 55 Double IPA", label: "Brew By Numbers - 55 Double IPA" },
  { value: "Brew By Numbers - 01 Saison", label: "Brew By Numbers - 01 Saison" },
  { value: "Wiper and True - Milk Shake", label: "Wiper and True - Milk Shake" },
  { value: "Wiper and True - Kaleidoscope", label: "Wiper and True - Kaleidoscope" },
  { value: "Burning Sky Brewery - Arise", label: "Burning Sky Brewery - Arise" },
  { value: "Burning Sky Brewery - Saison à la Provision", label: "Burning Sky Brewery - Saison à la Provision" },
  { value: "Mondo Brewing Company - Dennis Hopp’r", label: "Mondo Brewing Company - Dennis Hopp’r" },
  { value: "Mondo Brewing Company - Kemosabe", label: "Mondo Brewing Company - Kemosabe" },
  { value: "Five Points Brewing Company - Railway Porter", label: "Five Points Brewing Company - Railway Porter" },
  { value: "Five Points Brewing Company - Pale", label: "Five Points Brewing Company - Pale" },
  { value: "Cloudwater Brew Co - IPA", label: "Cloudwater Brew Co - IPA" },
  { value: "Cloudwater Brew Co - Pale Ale", label: "Cloudwater Brew Co - Pale Ale" },
  { value: "Deya Brewing Company - Steady Rolling Man", label: "Deya Brewing Company - Steady Rolling Man" },
  { value: "Deya Brewing Company - Into the Haze", label: "Deya Brewing Company - Into the Haze" },
  { value: "Canopy Session IPA", label: "Canopy Session IPA" },
  { value: "Hoptimistic", label: "Hoptimistic" },
  { value: "Helles Lager", label: "Helles Lager" },
  { value: "Super Sharp Shooter", label: "Super Sharp Shooter" },
  { value: "Keller Pils", label: "Keller Pils" },
  { value: "Wild Cat Cider", label: "Wild Cat Cider" },
  { value: "Saison D'Avon", label: "Saison D'Avon" },
  { value: "Yawn Pale Ale", label: "Yawn Pale Ale" },
  { value: "God's Twisted Sister Stout", label: "God's Twisted Sister Stout" },
  { value: "Monster from Munich", label: "Monster from Munich" },
  { value: "House Lager", label: "House Lager" },
  { value: "House Ale", label: "House Ale" },
  { value: "Beavertown Bones", label: "Beavertown Bones" },
  { value: "Mahou", label: "Mahou" },
  { value: "TLP Dry Hops Ale", label: "TLP Dry Hops Ale" },
  { value: "Beavertown - Lazer Crush", label: "Beavertown - Lazer Crush" },
  { value: "Porterhouse Yippy IPA", label: "Porterhouse Yippy IPA" },
  { value: "Porterhouse Nitro Red Ale", label: "Porterhouse Nitro Red Ale" },
  { value: "Porterhouse Plain Porter", label: "Porterhouse Plain Porter" },
  { value: "Porterhouse Oyster Stout", label: "Porterhouse Oyster Stout" },
  { value: "Sambrooks Session Pale Ale", label: "Sambrooks Session Pale Ale" },
  { value: "Stone Neverending Haze IPA", label: "Stone Neverending Haze IPA" },
  { value: "Verdant Brewing Co - Headband", label: "Verdant Brewing Co - Headband" },
  { value: "Verdant Brewing Co - Lightbulb", label: "Verdant Brewing Co - Lightbulb" },
  { value: "Polly's Brew Co - Augment", label: "Polly's Brew Co - Augment" },
  { value: "Polly's Brew Co - Lupo", label: "Polly's Brew Co - Lupo" },
  { value: "Arbor Ales - Shangri-La", label: "Arbor Ales - Shangri-La" },
  { value: "Arbor Ales - Mosaic", label: "Arbor Ales - Mosaic" },
  { value: "Anspach & Hobday - The Pale Ale", label: "Anspach & Hobday - The Pale Ale" },
  { value: "Anspach & Hobday - The Smoked Brown", label: "Anspach & Hobday - The Smoked Brown" },
  { value: "Gipsy Hill - Hepcat", label: "Gipsy Hill - Hepcat" },
  { value: "Gipsy Hill - Bandit", label: "Gipsy Hill - Bandit" },
  { value: "Partizan Brewing - Lemon & Thyme Saison", label: "Partizan Brewing - Lemon & Thyme Saison" },
  { value: "Partizan Brewing - Pale Ale", label: "Partizan Brewing - Pale Ale" },
  { value: "Howling Hops - House IPA", label: "Howling Hops - House IPA" },
  { value: "Howling Hops - Tropical Deluxe", label: "Howling Hops - Tropical Deluxe" },
  { value: "Brick Brewery - Peckham Pils", label: "Brick Brewery - Peckham Pils" },
  { value: "Brick Brewery - Peckham Pale", label: "Brick Brewery - Peckham Pale" },
  { value: "Signature Brew - Roadie", label: "Signature Brew - Roadie" },
  { value: "Signature Brew - Studio Pilsner", label: "Signature Brew - Studio Pilsner" },
  { value: "Weird Beard - Black Perle", label: "Weird Beard - Black Perle" },
  { value: "Weird Beard - Little Things That Kill", label: "Weird Beard - Little Things That Kill" },
  { value: "Burnt Mill - Pintle Pale Ale", label: "Burnt Mill - Pintle Pale Ale" },
  { value: "Burnt Mill - Citra Fog IPA", label: "Burnt Mill - Citra Fog IPA" },
  { value: "Duration Brewing - Bet The Farm", label: "Duration Brewing - Bet The Farm" },
  { value: "Duration Brewing - Turtles All The Way Down", label: "Duration Brewing - Turtles All The Way Down" },
  { value: "Newbarns Brewery - Pale Ale", label: "Newbarns Brewery - Pale Ale" },
  { value: "Newbarns Brewery - Red Ale", label: "Newbarns Brewery - Red Ale" },
  { value: "Utopian Brewing - Unfiltered Helles Lager", label: "Utopian Brewing - Unfiltered Helles Lager" },
  { value: "Utopian Brewing - British Pilsner", label: "Utopian Brewing - British Pilsner" },
  { value: "Rivington Brewing Co - Pale", label: "Rivington Brewing Co - Pale" },
  { value: "Rivington Brewing Co - Hazy Pale", label: "Rivington Brewing Co - Hazy Pale" },
  { value: "Boxcar Brewery - Dark Mild", label: "Boxcar Brewery - Dark Mild" },
  { value: "Boxcar Brewery - Dreamful IPA", label: "Boxcar Brewery - Dreamful IPA" },
  { value: "Elusive Brewing - Level Up West Coast IPA", label: "Elusive Brewing - Level Up West Coast IPA" },
  { value: "Elusive Brewing - Oregon Trail American Pale", label: "Elusive Brewing - Oregon Trail American Pale" },
  { value: "Neon Raptor - Sound Wave", label: "Neon Raptor - Sound Wave" },
  { value: "Neon Raptor - Endangered", label: "Neon Raptor - Endangered" },
  { value: "Pastore Brewing - Peach Melba Sour", label: "Pastore Brewing - Peach Melba Sour" },
  { value: "Pastore Brewing - Salted Caramel Mud Cake Stout", label: "Pastore Brewing - Salted Caramel Mud Cake Stout" },
  { value: "Overtone Brewing Co - Pale Ale", label: "Overtone Brewing Co - Pale Ale" },
  { value: "Overtone Brewing Co - Elements IPA", label: "Overtone Brewing Co - Elements IPA" },
  { value: "Pilot - Peach Melba Sour", label: "Pilot - Peach Melba Sour" },
  { value: "Pilot - Vienna Pale", label: "Pilot - Vienna Pale" },
  { value: "Queer Brewing - Existence As A Radical Act", label: "Queer Brewing - Existence As A Radical Act" },
  { value: "Queer Brewing - Flowers For The Void", label: "Queer Brewing - Flowers For The Void" },
  { value: "Donzoko Brewing - Northern Helles", label: "Donzoko Brewing - Northern Helles" },
  { value: "Donzoko Brewing - Dark Mild", label: "Donzoko Brewing - Dark Mild" },
  { value: "Brew York - Calmer Chameleon", label: "Brew York - Calmer Chameleon" },
  { value: "Brew York - Rhubarbra Streisand", label: "Brew York - Rhubarbra Streisand" },
  { value: "Abyss Brewing - Unbeknown Helles Lager", label: "Abyss Brewing - Unbeknown Helles Lager" },
  { value: "Abyss Brewing - Patchwork Rocket IPA", label: "Abyss Brewing - Patchwork Rocket IPA" },
  { value: "Turning Point Brew Co - Disco King", label: "Turning Point Brew Co - Disco King" },
  { value: "Turning Point Brew Co - Lucid Dream", label: "Turning Point Brew Co - Lucid Dream" },
  { value: "Vault City - Tropical Sour", label: "Vault City - Tropical Sour" },
  { value: "Vault City - Session Sour", label: "Vault City - Session Sour" },
  { value: "Fierce Beer - Tangerine Tart", label: "Fierce Beer - Tangerine Tart" },
  { value: "Fierce Beer - Cranachan Killer", label: "Fierce Beer - Cranachan Killer" },
  { value: "Black Iris Brewery - Snake Eyes", label: "Black Iris Brewery - Snake Eyes" },
  { value: "Black Iris Brewery - Endless Summer", label: "Black Iris Brewery - Endless Summer" },
  { value: "Torrside Brewing - I'm So Bored With The USA", label: "Torrside Brewing - I'm So Bored With The USA" },
  { value: "Torrside Brewing - Monsters of the Dark", label: "Torrside Brewing - Monsters of the Dark" },
  { value: "Almasty Brewing Co - West Coast Pale", label: "Almasty Brewing Co - West Coast Pale" },
  { value: "Almasty Brewing Co - Space Hopper", label: "Almasty Brewing Co - Space Hopper" },
  { value: "Odyssey Brew Co - Simcoe Pale", label: "Odyssey Brew Co - Simcoe Pale" },
  { value: "Odyssey Brew Co - Mosaic Pale", label: "Odyssey Brew Co - Mosaic Pale" },
  { value: "Yeastie Boys - Gunnamatta", label: "Yeastie Boys - Gunnamatta" },
  { value: "Yeastie Boys - Digital IPA", label: "Yeastie Boys - Digital IPA" },
  { value: "Siren Craft Brew - Suspended in Space", label: "Siren Craft Brew - Suspended in Space" },
  { value: "Siren Craft Brew - Futurist IPA", label: "Siren Craft Brew - Futurist IPA" },
  { value: "Thornbridge - Necessary Evil", label: "Thornbridge - Necessary Evil" },
  { value: "Deya Brewing Company - Steady Rolling Man", label: "Deya Brewing Company - Steady Rolling Man" },
  { value: "Deya Brewing Company - Into the Haze", label: "Deya Brewing Company - Into the Haze" },
  { value: "Verdant Brewing Co - Some Fifty", label: "Verdant Brewing Co - Some Fifty" },
  { value: "Verdant Brewing Co - The Experiment", label: "Verdant Brewing Co - The Experiment" },
  { value: "Cloudwater Brew Co - Small Pils", label: "Cloudwater Brew Co - Small Pils" },
  { value: "Cloudwater Brew Co - Chubbles", label: "Cloudwater Brew Co - Chubbles" },
  { value: "North Brewing Co - Full Fathom 5", label: "North Brewing Co - Full Fathom 5" },
  { value: "North Brewing Co - Piñata", label: "North Brewing Co - Piñata" },
  { value: "Wylam Brewery - Club of Slaughters", label: "Wylam Brewery - Club of Slaughters" },
  { value: "Wylam Brewery - Macchiato", label: "Wylam Brewery - Macchiato" },
  { value: "Buxton Brewery - Kingslayer", label: "Buxton Brewery - Kingslayer" },
  { value: "Buxton Brewery - Extra Porter", label: "Buxton Brewery - Extra Porter" },
  { value: "Kernel Brewery - India Pale Ale Mosaic", label: "Kernel Brewery - India Pale Ale Mosaic" },
  { value: "Track Brewing Company - Sonoma", label: "Track Brewing Company - Sonoma" },
  { value: "Track Brewing Company - Tenaya", label: "Track Brewing Company - Tenaya" },
  { value: "Marble Beers - Pint", label: "Marble Beers - Pint" },
  { value: "Marble Beers - Decadence", label: "Marble Beers - Decadence" },
  { value: "Thornbridge Brewery - Lucaria", label: "Thornbridge Brewery - Lucaria" },
  { value: "Thornbridge Brewery - Florida Weisse", label: "Thornbridge Brewery - Florida Weisse" },
  { value: "Magic Rock Brewing - Inhaler", label: "Magic Rock Brewing - Inhaler" },
  { value: "Magic Rock Brewing - Salty Kiss", label: "Magic Rock Brewing - Salty Kiss" },
  { value: "Wild Beer Co - Bibble", label: "Wild Beer Co - Bibble" },
  { value: "Wild Beer Co - Nebula", label: "Wild Beer Co - Nebula" },
  { value: "Pressure Drop Brewing - Pale Fire", label: "Pressure Drop Brewing - Pale Fire" },
  { value: "Pressure Drop Brewing - Bosko", label: "Pressure Drop Brewing - Bosko" },
  { value: "Brew By Numbers - 55|01 Double IPA", label: "Brew By Numbers - 55|01 Double IPA" },
  { value: "Brew By Numbers - 01|28 Saison Citra", label: "Brew By Numbers - 01|28 Saison Citra" },
  { value: "Burning Sky Brewery - Arise", label: "Burning Sky Brewery - Arise" },
  { value: "Burning Sky Brewery - Saison à la Provision", label: "Burning Sky Brewery - Saison à la Provision" },
  { value: "Moor Beer Company - Nor'Hop", label: "Moor Beer Company - Nor'Hop" },
  { value: "Moor Beer Company - Illusion", label: "Moor Beer Company - Illusion" },
  { value: "Vocation Brewery - Pride & Joy", label: "Vocation Brewery - Pride & Joy" },
  { value: "Vocation Brewery - Bread & Butter", label: "Vocation Brewery - Bread & Butter" },
  { value: "Mondo Brewing Company - Dennis Hopp'r", label: "Mondo Brewing Company - Dennis Hopp'r" },
  { value: "Mondo Brewing Company - London Alt", label: "Mondo Brewing Company - London Alt" },
  { value: "Anspach & Hobday - The IPA", label: "Anspach & Hobday - The IPA" },
  { value: "Anspach & Hobday - The Ordinary Bitter", label: "Anspach & Hobday - The Ordinary Bitter" },
  { value: "Partizan Brewing - IPA Citra", label: "Partizan Brewing - IPA Citra" },
  { value: "Partizan Brewing - Porter", label: "Partizan Brewing - Porter" },
  { value: "Harbour Brewing Co - Daymer Extra Pale Ale", label: "Harbour Brewing Co - Daymer Extra Pale Ale" },
  { value: "Harbour Brewing Co - Little Rock IPA", label: "Harbour Brewing Co - Little Rock IPA" },
  { value: "Saltaire Brewery - Zipwire", label: "Saltaire Brewery - Zipwire" },
  { value: "Saltaire Brewery - Velocity", label: "Saltaire Brewery - Velocity" },
  { value: "Titanic Brewery - Plum Porter", label: "Titanic Brewery - Plum Porter" },
  { value: "Titanic Brewery - Iceberg", label: "Titanic Brewery - Iceberg" },
  { value: "Roosters Brewing Co - Baby-Faced Assassin", label: "Roosters Brewing Co - Baby-Faced Assassin" },
  { value: "Roosters Brewing Co - 24/7", label: "Roosters Brewing Co - 24/7" },
  { value: "Fyne Ales - Jarl", label: "Fyne Ales - Jarl" },
  { value: "Fyne Ales - Highlander", label: "Fyne Ales - Highlander" },
  { value: "Tempest Brewing Co - Brave New World", label: "Tempest Brewing Co - Brave New World" },
  { value: "Tempest Brewing Co - Long White Cloud", label: "Tempest Brewing Co - Long White Cloud" },
  { value: "Hawkshead Brewery - Windermere Pale", label: "Hawkshead Brewery - Windermere Pale" },
  { value: "Hawkshead Brewery - Brodie's Prime", label: "Hawkshead Brewery - Brodie's Prime" },
  { value: "Thornbridge - Cocoa Wonderland", label: "Thornbridge - Cocoa Wonderland" },
  { value: "Thornbridge - Jaipur X", label: "Thornbridge - Jaipur X" },
  { value: "Siren Craft Brew - Lumina Session IPA", label: "Siren Craft Brew - Lumina Session IPA" },
  { value: "Siren Craft Brew - Yu Lu Loose Leaf Pale", label: "Siren Craft Brew - Yu Lu Loose Leaf Pale" },
  { value: "Vibrant Forest - Pupa", label: "Vibrant Forest - Pupa" },
  { value: "Vibrant Forest - Black Forest", label: "Vibrant Forest - Black Forest" },
  { value: "Abbeydale Brewery - Moonshine", label: "Abbeydale Brewery - Moonshine" },
  { value: "Abbeydale Brewery - Deception", label: "Abbeydale Brewery - Deception" },
  { value: "Pomona Island Brew Co - Pale", label: "Pomona Island Brew Co - Pale" },
  { value: "Pomona Island Brew Co - Factotum", label: "Pomona Island Brew Co - Factotum" },
  { value: "Wiper and True - Milk Shake", label: "Wiper and True - Milk Shake" },
  { value: "Wiper and True - Kaleidoscope", label: "Wiper and True - Kaleidoscope" },
  { value: "Canopy Beer Co - Brockwell IPA", label: "Canopy Beer Co - Brockwell IPA" },
  { value: "Canopy Beer Co - Sunray Pale Ale", label: "Canopy Beer Co - Sunray Pale Ale" },
  { value: "Solvay Society - Coulomb", label: "Solvay Society - Coulomb" },
  { value: "Solvay Society - Newton", label: "Solvay Society - Newton" },
  { value: "Exale Brewing - Krankie", label: "Exale Brewing - Krankie" },
  { value: "Exale Brewing - Cat Nap", label: "Exale Brewing - Cat Nap" },
  { value: "Ampersand Brew Co - Bidon", label: "Ampersand Brew Co - Bidon" },
  { value: "Ampersand Brew Co - Cocow", label: "Ampersand Brew Co - Cocow" },
  { value: "Brick Brewery - Peckham Rye Amber", label: "Brick Brewery - Peckham Rye Amber" },
  { value: "Brick Brewery - Blenheim Black IPA", label: "Brick Brewery - Blenheim Black IPA" },
  { value: "Anspach & Hobday - The Sour Dry Hop", label: "Anspach & Hobday - The Sour Dry Hop" },
  { value: "Anspach & Hobday - The Cream Ale", label: "Anspach & Hobday - The Cream Ale" },
  { value: "Gipsy Hill - Carver", label: "Gipsy Hill - Carver" },
  { value: "Gipsy Hill - Ranger", label: "Gipsy Hill - Ranger" },
  { value: "Howling Hops - Tropical Deluxe", label: "Howling Hops - Tropical Deluxe" },
  { value: "Howling Hops - Riding Ale", label: "Howling Hops - Riding Ale" },
  { value: "Signature Brew - Roadie", label: "Signature Brew - Roadie" },
  { value: "Signature Brew - Backstage IPA", label: "Signature Brew - Backstage IPA" },
  { value: "Weird Beard - Black Perle", label: "Weird Beard - Black Perle" },
  { value: "Weird Beard - Little Things That Kill", label: "Weird Beard - Little Things That Kill" },
  { value: "Hackney Brewery - New Zealand Pale", label: "Hackney Brewery - New Zealand Pale" },
  { value: "Hackney Brewery - American Pale", label: "Hackney Brewery - American Pale" },
  { value: "Redchurch Brewery - Hoxton Stout", label: "Redchurch Brewery - Hoxton Stout" },
  { value: "Redchurch Brewery - Great Eastern IPA", label: "Redchurch Brewery - Great Eastern IPA" },
  { value: "One Mile End - Juice Box Citrus IPA", label: "One Mile End - Juice Box Citrus IPA" },
  { value: "One Mile End - Hospital Porter", label: "One Mile End - Hospital Porter" },
  { value: "Orbit Beers - Nico", label: "Orbit Beers - Nico" },
  { value: "Orbit Beers - Peel", label: "Orbit Beers - Peel" },
  { value: "Hammerton Brewery - Crunch", label: "Hammerton Brewery - Crunch" },
  { value: "Hammerton Brewery - N7", label: "Hammerton Brewery - N7" },
  { value: "Affinity Brew Co - Breeze", label: "Affinity Brew Co - Breeze" },
  { value: "Affinity Brew Co - Pale", label: "Affinity Brew Co - Pale" },
  { value: "Fierce Beer - Very Big Moose", label: "Fierce Beer - Very Big Moose" },
  { value: "Fierce Beer - Cafe Racer", label: "Fierce Beer - Cafe Racer" },
  { value: "Neon Raptor - Crimson Sunset", label: "Neon Raptor - Crimson Sunset" },
  { value: "Neon Raptor - Oatmeal Stout", label: "Neon Raptor - Oatmeal Stout" },
  { value: "Vault City Brewing - Strawberry Sundae", label: "Vault City Brewing - Strawberry Sundae" },
  { value: "Vault City Brewing - Tropical Crush", label: "Vault City Brewing - Tropical Crush" },
  { value: "Newbarns Brewery - Pale Ale", label: "Newbarns Brewery - Pale Ale" },
  { value: "Newbarns Brewery - Red Ale", label: "Newbarns Brewery - Red Ale" },
  { value: "Utopian Brewing - Unfiltered Helles Lager", label: "Utopian Brewing - Unfiltered Helles Lager" },
  { value: "Utopian Brewing - British Pilsner", label: "Utopian Brewing - British Pilsner" },
  { value: "Rivington Brewing Co - Pale", label: "Rivington Brewing Co - Pale" },
  { value: "Rivington Brewing Co - Hazy Pale", label: "Rivington Brewing Co - Hazy Pale" },
  { value: "Boxcar Brewery - Dark Mild", label: "Boxcar Brewery - Dark Mild" },
  { value: "Boxcar Brewery - Dreamful IPA", label: "Boxcar Brewery - Dreamful IPA" },
  { value: "Elusive Brewing - Level Up West Coast IPA", label: "Elusive Brewing - Level Up West Coast IPA" },
  { value: "Elusive Brewing - Oregon Trail American Pale", label: "Elusive Brewing - Oregon Trail American Pale" },
  { value: "Pastore Brewing - Peach Melba Sour", label: "Pastore Brewing - Peach Melba Sour" },
  { value: "Pastore Brewing - Salted Caramel Mud Cake Stout", label: "Pastore Brewing - Salted Caramel Mud Cake Stout" },
  { value: "Overtone Brewing Co - Pale Ale", label: "Overtone Brewing Co - Pale Ale" },
  { value: "Overtone Brewing Co - Elements IPA", label: "Overtone Brewing Co - Elements IPA" },
  { value: "Pilot - Peach Melba Sour", label: "Pilot - Peach Melba Sour" },
  { value: "Pilot - Vienna Pale", label: "Pilot - Vienna Pale" },
  { value: "Queer Brewing - Existence As A Radical Act", label: "Queer Brewing - Existence As A Radical Act" },
  { value: "Queer Brewing - Flowers For The Void", label: "Queer Brewing - Flowers For The Void" },
  { value: "Donzoko Brewing - Northern Helles", label: "Donzoko Brewing - Northern Helles" },
  { value: "Donzoko Brewing - Dark Mild", label: "Donzoko Brewing - Dark Mild" },
  { value: "Abyss Brewing - Unbeknown Helles Lager", label: "Abyss Brewing - Unbeknown Helles Lager" },
  { value: "Abyss Brewing - Patchwork Rocket IPA", label: "Abyss Brewing - Patchwork Rocket IPA" },
  { value: "Turning Point Brew Co - Disco King", label: "Turning Point Brew Co - Disco King" },
  { value: "Turning Point Brew Co - Lucid Dream", label: "Turning Point Brew Co - Lucid Dream" },
  { value: "Black Iris Brewery - Snake Eyes", label: "Black Iris Brewery - Snake Eyes" },
  { value: "Black Iris Brewery - Endless Summer", label: "Black Iris Brewery - Endless Summer" },
  { value: "Torrside Brewing - I'm So Bored With The USA", label: "Torrside Brewing - I'm So Bored With The USA" },
  { value: "Torrside Brewing - Monsters of the Dark", label: "Torrside Brewing - Monsters of the Dark" },
  { value: "Almasty Brewing Co - West Coast Pale", label: "Almasty Brewing Co - West Coast Pale" },
  { value: "Almasty Brewing Co - Space Hopper", label: "Almasty Brewing Co - Space Hopper" },
  { value: "Odyssey Brew Co - Simcoe Pale", label: "Odyssey Brew Co - Simcoe Pale" },
  { value: "Odyssey Brew Co - Mosaic Pale", label: "Odyssey Brew Co - Mosaic Pale" },
  { value: "Yeastie Boys - Gunnamatta", label: "Yeastie Boys - Gunnamatta" },
  { value: "Yeastie Boys - Digital IPA", label: "Yeastie Boys - Digital IPA" },
  { value: "Unity Brewing Co - Conflux Pale Ale", label: "Unity Brewing Co - Conflux Pale Ale" },
  { value: "Unity Brewing Co - Collision IPA", label: "Unity Brewing Co - Collision IPA" },
  { value: "Stannary Brewing Co - Yellowhammer", label: "Stannary Brewing Co - Yellowhammer" },
  { value: "Stannary Brewing Co - Black Prince", label: "Stannary Brewing Co - Black Prince" },
  { value: "Padstow Brewing Co - Pocket Rocket", label: "Padstow Brewing Co - Pocket Rocket" },
  { value: "Padstow Brewing Co - Sundowner", label: "Padstow Brewing Co - Sundowner" },
  { value: "Fallen Brewing - Platform C", label: "Fallen Brewing - Platform C" },
  { value: "Fallen Brewing - New World Odyssey", label: "Fallen Brewing - New World Odyssey" },
  { value: "Thornbridge - Necessary Evil", label: "Thornbridge - Necessary Evil" },
  { value: "Whiplash - Body Riddle", label: "Whiplash - Body Riddle" },
  { value: "Whiplash - Bone Machine", label: "Whiplash - Bone Machine" },
  { value: "Boundary - Export Stout", label: "Boundary - Export Stout" },
  { value: "Boundary - Push & Pull", label: "Boundary - Push & Pull" },
  { value: "Galway Bay - Of Foam and Fury", label: "Galway Bay - Of Foam and Fury" },
  { value: "Galway Bay - Buried at Sea", label: "Galway Bay - Buried at Sea" },
  { value: "Kinnegar - Scraggy Bay", label: "Kinnegar - Scraggy Bay" },
  { value: "Kinnegar - Limeburner", label: "Kinnegar - Limeburner" },
  { value: "White Hag - Little Fawn", label: "White Hag - Little Fawn" },
  { value: "White Hag - Black Boar", label: "White Hag - Black Boar" },
  { value: "Yellowbelly - Citra Pale Ale", label: "Yellowbelly - Citra Pale Ale" },
  { value: "Yellowbelly - Kellerbier", label: "Yellowbelly - Kellerbier" },
  { value: "O Brother - The Sinner", label: "O Brother - The Sinner" },
  { value: "O Brother - The Fixer", label: "O Brother - The Fixer" },
  { value: "Rascals - Big Hop Red", label: "Rascals - Big Hop Red" },
  { value: "Rascals - Wunderbar IPA", label: "Rascals - Wunderbar IPA" },
  { value: "Hope Beer - Handsome Jack", label: "Hope Beer - Handsome Jack" },
  { value: "Hope Beer - Grunt", label: "Hope Beer - Grunt" },
  { value: "Wicklow Wolf - Locavore", label: "Wicklow Wolf - Locavore" },
  { value: "Wicklow Wolf - Moonlight", label: "Wicklow Wolf - Moonlight" },
  { value: "Trouble Brewing - Ambush", label: "Trouble Brewing - Ambush" },
  { value: "Trouble Brewing - Dark Arts", label: "Trouble Brewing - Dark Arts" },
  { value: "Black Donkey - Sheep Stealer", label: "Black Donkey - Sheep Stealer" },
  { value: "Black Donkey - Buck It", label: "Black Donkey - Buck It" },
  { value: "Porterhouse - Plain Porter", label: "Porterhouse - Plain Porter" },
  { value: "Porterhouse - Hop Head", label: "Porterhouse - Hop Head" },
  { value: "Eight Degrees - Knockmealdown", label: "Eight Degrees - Knockmealdown" },
  { value: "Eight Degrees - Bohemian Pilsner", label: "Eight Degrees - Bohemian Pilsner" },
  { value: "Metalman - Pale Ale", label: "Metalman - Pale Ale" },
  { value: "Metalman - Equinox", label: "Metalman - Equinox" },
  { value: "12 Acres - Single Malt Lager", label: "12 Acres - Single Malt Lager" },
  { value: "12 Acres - Pale Ale", label: "12 Acres - Pale Ale" },
  { value: "Dot Brew - Rye River", label: "Dot Brew - Rye River" },
  { value: "Dot Brew - Barrel Aged Imperial Stout", label: "Dot Brew - Barrel Aged Imperial Stout" },
  { value: "Larkins - Helles Lager", label: "Larkins - Helles Lager" },
  { value: "Larkins - Traditional Red Ale", label: "Larkins - Traditional Red Ale" },
  { value: "Ballykilcavan - Bin Bawn Pale Ale", label: "Ballykilcavan - Bin Bawn Pale Ale" },
  { value: "Ballykilcavan - Long Meadow IPA", label: "Ballykilcavan - Long Meadow IPA" },
  { value: "Dungarvan - Black Rock Irish Stout", label: "Dungarvan - Black Rock Irish Stout" },
  { value: "Dungarvan - Copper Coast Red Ale", label: "Dungarvan - Copper Coast Red Ale" },
  { value: "9 White Deer - Stag Bán", label: "9 White Deer - Stag Bán" },
  { value: "9 White Deer - Black Lightning", label: "9 White Deer - Black Lightning" },
  { value: "West Cork Brewing - Sherkin Lass", label: "West Cork Brewing - Sherkin Lass" },
  { value: "West Cork Brewing - Roaringwater Bay IPA", label: "West Cork Brewing - Roaringwater Bay IPA" },
  { value: "Blacks of Kinsale - KPA", label: "Blacks of Kinsale - KPA" },
  { value: "Blacks of Kinsale - The Session", label: "Blacks of Kinsale - The Session" },
  { value: "Boyne Brewhouse - Born in a Day", label: "Boyne Brewhouse - Born in a Day" },
  { value: "Boyne Brewhouse - Long Arm", label: "Boyne Brewhouse - Long Arm" },
  { value: "Carlow Brewing Company - O'Hara's Irish Stout", label: "Carlow Brewing Company - O'Hara's Irish Stout" },
  { value: "Carlow Brewing Company - Leann Folláin", label: "Carlow Brewing Company - Leann Folláin" },



    









      
    
    




    // Add more beer types as needed
  ];


  export const getBeerLogoPath = (beerName) => {
    try {
      const formattedName = beerName.toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')  // Replace any non-alphanumeric characters with a dash
        .replace(/^-+|-+$/g, '');     // Remove leading and trailing dashes
      return require(`../assets/beer-logos/${formattedName}.png`);
    } catch (error) {
      console.error(`Failed to load image for ${beerName}:`, error);
      return require('../assets/beer-logos/default-beer.png');
    }
  };
  
  export default beerTypes;