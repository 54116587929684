import React, { useState } from 'react';
import greenIcon from '../assets/green-icon.svg';
import orangeIcon from '../assets/orange-icon.svg';
import redIcon from '../assets/red-icon.svg';
import star from '../assets/Star.png';
import '../styles/mapkeyStyles.css'; // Import the CSS file

const MapLegend = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="map-legend-container">
      <button className="toggle-button" onClick={toggleVisibility}>
        {isVisible ? 'Close' : 'Map key 🔑'}
      </button>
      {isVisible && (
        <div className="map-legend">
          <div className="legend-item">
            <img src={greenIcon} alt="Low Price" />
            <div className="legend-label">Less than £6</div>
          </div>
          <div className="legend-item">
            <img src={orangeIcon} alt="Medium Price" />
            <div className="legend-label">Between £6-£7</div>
          </div>
          <div className="legend-item">
            <img src={redIcon} alt="High Price" />
            <div className="legend-label">More than £7</div>
          </div>
          <div className="legend-item">
            <img src={star} alt="Verified" />
            <div className="legend-label">Verified (More than 3 pints of the same price recorded)</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapLegend;
