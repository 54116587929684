import React, { useState } from 'react';


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div
        className="flex justify-between items-center border-solid border-2 bg-white-200 p-3 rounded-md cursor-pointer transition duration-300"
        onClick={toggleAnswer}
      >
        <strong>{question}</strong>
        <div
          className={`transform ${isOpen ? 'rotate-90' : 'rotate-0'} transition-transform`}
        >
          🔽
        </div>
      </div>
      {isOpen && (
        <div className="mt-2 bg-slate-100 p-3 rounded-md">
          <p>{answer}</p>
          <div dangerouslySetInnerHTML={{ __html: answer.html }} />
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  const faqData = [
    {
      question: 'How does it work?',
      answer: "All the pints contributed are crowdsourced, meaning people self report the price when they come across them. Even if they have a website, most pubs do not publish their beer prices online and it can be really hard to find a good cheap pint from a local."
    },
    // {
    //     question: 'Na na, I mean how does it technically work?',
    //     answer: "I've written about how I'm building this",
    //     html: 'I\'ve written about how I\'m building <a href="https://example.com">this</a>.',

    //   },
    {
      question: 'How do I know the price is accurate?',
      answer: "The whole idea around this site is pint drinkers sharing amongst pint drinkers the best and cheapest local pubs to go to near you. I can't guarantee that every pint submission is accurate."
    },
    {
        question: 'What about bogus pint reports?',
        answer: "I've done a bunch of work in the form to make sure people can't put fake answers in and to keep the pint prices as accurate as possible. I also manually review and remove one's that fall through the cracks"
      },
      {
        question: 'Do you make money off this?',
        answer: "Hosting this site has started costing me money. I've had to include a couple of ads on here now to try and fund it. If you've found the data on this website useful, please feel free to Buy me a pint ⬇️"
      },
    // Add more FAQ items as needed
  ];

  return (
    <div className='p-2'>
      <h2 className="text-2xl font-bold mb-4 mt-4">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQs;
