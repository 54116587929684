import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { getDatabase, ref, query, orderByChild, limitToLast, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { format, parseISO, startOfMonth, subMonths } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

const CheapestPintChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Cheapest Pint Price',
        data: [],
        borderColor: '#16a349',
        backgroundColor: 'rgba(22, 163, 73, 0.2)',
        tension: 0.3,
        fill: true,
        pointRadius: 4,
        pointBackgroundColor: '#16a349',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: '#16a349',
        pointHoverBorderColor: '#ffffff',
        pointHoverBorderWidth: 2
      }
    ]
  });

  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp);
    const pricesRef = ref(database, "beerPrices");
    const pricesQuery = query(pricesRef, orderByChild("price"), limitToLast(500));

    const unsubscribe = onValue(pricesQuery, (snapshot) => {
      const prices = [];
      snapshot.forEach((childSnapshot) => {
        const price = childSnapshot.val();
        prices.push({
          price: price.price,
          timestamp: price.timestamp,
          beerType: price.beerType
        });
      });

      // Group prices by month
      const monthlyPrices = prices.reduce((acc, price) => {
        const monthStart = startOfMonth(parseISO(price.timestamp));
        const monthKey = format(monthStart, 'yyyy-MM');
        if (!acc[monthKey] || price.price < acc[monthKey].price) {
          acc[monthKey] = price;
        }
        return acc;
      }, {});

      // Generate an array of the last 12 months
      const today = new Date();
      const last12Months = Array.from({ length: 12 }, (_, i) => {
        const date = subMonths(today, i);
        return format(date, 'yyyy-MM');
      }).reverse();

      // Prepare data for the chart
      const chartData = last12Months.map(monthKey => {
        const price = monthlyPrices[monthKey];
        return {
          month: format(parseISO(monthKey), 'MMM'),
          price: price ? price.price : null,
          beerType: price ? price.beerType : null
        };
      });

      const labels = chartData.map(item => item.month);
      const data = chartData.map(item => item.price);
      const beerTypes = chartData.map(item => item.beerType);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Cheapest Pint Price',
            data,
            borderColor: '#16a349',
            backgroundColor: 'rgba(22, 163, 73, 0.2)',
            tension: 0.3,
            fill: true,
            pointRadius: 4,
            pointBackgroundColor: '#16a349',
            pointBorderColor: '#ffffff',
            pointBorderWidth: 2,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#16a349',
            pointHoverBorderColor: '#ffffff',
            pointHoverBorderWidth: 2
          }
        ],
        beerTypes
      });
    });

    return () => unsubscribe();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => `${context[0].label}`,
          label: (context) => {
            const beerType = chartData.beerTypes[context.dataIndex];
            return beerType ? `${beerType}: £${context.parsed.y.toFixed(2)}` : 'No data';
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (£)'
        },
        ticks: {
          callback: (value) => `£${value.toFixed(2)}`
        }
      },
      x: {
        title: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };

  return (
    <div className="w-full border rounded-lg p-4 border-gray-200 bg-white">
      <div className="">
        <h2 className="text-xl font-bold">Cheapest pint over time</h2>
      </div>
      <div className="w-full my-4">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export default CheapestPintChart;