import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { getDatabase, ref, onValue, off } from "firebase/database";
import { initializeApp } from "firebase/app";
import { formatDistanceToNow } from "date-fns";
import '../styles/CustomInfoWindow.css';
import MapLegend from './mapLegend';
import greenSvgIcon from '../assets/green-icon.svg';
import orangeSvgIcon from '../assets/orange-icon.svg';
import redSvgIcon from '../assets/red-icon.svg';
import blueDotSvgIcon from '../assets/blue-dot.svg';
import greenStarSvgIcon from '../assets/green-icon-star.svg';
import orangeStarSvgIcon from '../assets/orange-icon-star.svg';
import redStarSvgIcon from '../assets/red-icon-star.svg';
import selectedPubSvgIcon from '../assets/SelectedPub.png';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import Filters from './filters';
import { useMediaQuery } from 'react-responsive';
import LazyBeerLogo from "../LazyBeerLogo";
import { getGuinnessRatingLabel } from '../utils/guinnessRating';
import locationIcon from '../assets/location.svg';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

const center = [51.50, -0.1];

const LocationButton = () => {
  const map = useMap();

  const handleLocationClick = () => {
    map.locate().on("locationfound", function (e) {
      map.flyTo(e.latlng, 15);
    });
  };

  return (
    <button
      onClick={handleLocationClick}
      className="absolute z-[1000] bottom-24 right-4 bg-white p-2 rounded-lg shadow-lg hover:bg-gray-100"
      aria-label="Center map on your location"
    >
      <img src={locationIcon} alt="" className="w-6 h-6" />
    </button>
  );
};

const formatPrice = (price) => price.toFixed(2);

const CustomInfoWindow = ({ pub, count }) => {
  const findCheapestPint = (pints) => {
    return pints.reduce((cheapest, current) => {
      return current.price < cheapest.price ? current : cheapest;
    });
  };

  const cheapestPint = findCheapestPint(pub.pints);
  const otherPints = pub.pints.filter(pint => pint !== cheapestPint);

  return (
    <div className="mb-16 custom-info-window" role="dialog" aria-label={`Information for ${pub.pub}`}>
      <h2>
        <Link to={`/pub/${pub.id}`} className="text-xl text-green-600 underline font-bold mb-8">{pub.pub}</Link>
      </h2>
      <p className="font-normal text-slate-500 mt-4">Cheapest Pint:</p>
      <p className="flex items-center text-xl font-bold">
        {cheapestPint.beerType} | £{formatPrice(cheapestPint.price)}
      </p>
      {pub.guinnessRating && pub.beerType.toLowerCase() === 'guinness' && (
        <p className="font-medium text-slate-500">
          Guinness Rating: {pub.guinnessRating}/10
        </p>
      )}
      <p className="font-medium text-slate-500">
        Last Recorded: {formatDistanceToNow(new Date(pub.timestamp))} ago
      </p>
      <p className="font-medium text-slate-500">
        Contributions: {count}
      </p>
      <div className="other-pints mt-4 text-slate-700">
        <h3 className="text-md font-bold mb-1">Other Pints:</h3>
        {otherPints.length === 0 ? (
          <p className='text-sm text-slate-500 font-bold'>None</p>
        ) : (
          <ul>
            {otherPints.map((pint, index) => (
              <li key={index}>
                <p className='text-sm'>
                  {pint.beerType} · <strong>£{formatPrice(pint.price)}</strong> - {formatDistanceToNow(new Date(pint.timestamp))} ago
                  {pub.guinnessRating && pub.beerType.toLowerCase() === 'guinness' && (
                    <span className="align-middle bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2">
                      {getGuinnessRatingLabel(pub.guinnessRating)}
                    </span>
                  )}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <h3 className="text-md font-bold mb-2 mt-8">Other amenities:</h3>
      <ul>
        <li className="font-medium text-slate-500">
          🍺 Beer Garden: {pub.beerGarden ? 'Yes' : 'Not reported '}
        </li>
        <li className="font-medium text-slate-500">
          🎶 Live Music: {pub.liveMusic ? 'Yes' : 'Not reported'}
        </li>
        <li className="font-medium text-slate-500">
          ⚽️ Live Sports: {pub.liveSports ? 'Yes' : 'Not reported'}
        </li>
        <li className='font-medium text-slate-500'>
          🍻 Happy Hour: <b className={pub.happyHour ? 'text-green-600' : 'text-slate-500'}>{pub.happyHour ? 'Yes' : 'Not reported'}</b>
        </li>
      </ul>

      <Link to={`/pub/${pub.id}`} className="text-green-600 text-lg text-bold underline mt-4 font-semibold block">Pub details →</Link>
    </div>
  );
};

const BottomSheet = ({ pub, count, isOpen, onClose }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const sheetClassName = `
    fixed bg-white shadow-lg z-50 overflow-y-auto transition-all duration-300 ease-in-out
    ${isDesktop 
      ? 'top-0 right-0 h-full w-1/3 transform'
      : 'bottom-0 left-0 right-0 max-h-[80vh] rounded-t-2xl transform'}
    ${isOpen
      ? 'translate-x-0 translate-y-0'
      : isDesktop ? 'translate-x-full' : 'translate-y-full'}
  `;

  const overlayClassName = `
    fixed inset-0 bg-black z-40 transition-opacity duration-300 ease-in-out
    ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}
  `;

  return (
    <>
      <div 
        className={overlayClassName}
        onClick={onClose}
        aria-hidden="true"
      ></div>
      <div className={sheetClassName} role="dialog" aria-modal="true" aria-labelledby="bottomSheetTitle">
        <button 
          onClick={onClose} 
          className="bg-slate-100 px-3 pb-2 pt-1 rounded-full absolute top-4 right-4 text-2xl"
          aria-label="Close pub information"
        >
          &times;
        </button>
        <div className="p-4 sm:p-6">
          <h2 id="bottomSheetTitle" className="sr-only">Pub Information</h2>
          <CustomInfoWindow pub={pub} count={count} />
        </div>
      </div>
    </>
  );
};

const CheapestPintMap = () => {
  const [pubs, setPubs] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.50, -0.1]);
  const [mapZoom, setMapZoom] = useState(13);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [filters, setFilters] = useState({
    wetherspoons: 'show',
    amenities: [],
  });
  const [selectedPub, setSelectedPub] = useState(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showBeerMileRoute, setShowBeerMileRoute] = useState(false);

  const toggleBeerMileRoute = () => {
    setShowBeerMileRoute(!showBeerMileRoute);
  };


  const mapRef = useRef(null);

  const createCustomIcon = (iconUrl) => {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [48, 56],
      iconAnchor: [24, 56],
      popupAnchor: [0, -56],
    });
  };

  const shouldHaveStar = (pints) => {
    const pintCounts = pints.reduce((acc, pint) => {
      const key = `${pint.beerType}_${pint.price}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    return Object.values(pintCounts).some(count => count >= 3);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp);
    const pubsRef = ref(database, "beerPrices");
  
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      const pubData = data ? Object.values(data) : [];
      const pubMap = {};
  
      pubData.forEach((pub) => {
        if (!pubMap[pub.pub]) {
          pubMap[pub.pub] = {
            ...pub,
            pints: [],
            id: pub.pub.replace(/\s+/g, '-').toLowerCase(),
          };
        }
        pubMap[pub.pub].pints.push({
          beerType: pub.beerType,
          price: pub.price,
          timestamp: pub.timestamp,
          guinnessRating: pub.guinnessRating,
        });
      });
  
      const getUserLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setUserLocation([latitude, longitude]);
              setMapCenter([latitude, longitude]);
              setMapZoom(14);
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        }
      };
    
      getUserLocation();
  
      const updatedPubs = Object.values(pubMap)
      .map((pub) => ({
        ...pub,
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lng),
        cheapestPint: pub.price,
        lastRecorded: pub.timestamp,
        beerGarden: pub.beerGarden,
        liveSports: pub.liveSports,
        liveMusic: pub.liveMusic,
      }))
      .filter((pub) => {
        const isWetherspoons = pub.pub.toLowerCase().includes('wetherspoon');
        
        if (isWetherspoons && filters.wetherspoons === 'hide') {
          return false;
        }
        
        if (filters.amenities.includes('beer-garden') && !pub.beerGarden) {
          return false;
        }
        if (filters.amenities.includes('live-music') && !pub.liveMusic) {
          return false;
        }
        if (filters.amenities.includes('live-sport') && !pub.liveSports) {
          return false;
        }
        return true;
      });

    setPubs(updatedPubs);
  };

  onValue(pubsRef, onDataChange);

  return () => {
    off(pubsRef, onDataChange);
  };
}, [filters]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = useCallback(async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchInput)}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
    );
    const data = await response.json();
    const results = data.features.map(feature => ({
      placeName: feature.place_name,
      lat: feature.geometry.coordinates[1],
      lng: feature.geometry.coordinates[0]
    }));
    setSearchResults(results);

    if (results.length > 0) {
      setMapCenter([results[0].lat, results[0].lng]);
      setMapZoom(14);
      if (mapRef.current) {
        mapRef.current.setView([results[0].lat, results[0].lng], 14);
      }
    }
  }, [searchInput]);

  const MapContent = ({ setMapCenter, setMapZoom }) => {
    const map = useMap();
  
    useMapEvents({
      moveend: () => {
        setMapCenter(map.getCenter());
        setMapZoom(map.getZoom());
      },
    });
  
    return null;
  };


  const BeerMileRoute = () => {
    const map = useMap();
    
    useEffect(() => {
      if (!map) return;
  
      const routeCoordinates = [
        [51.50196477096108, -0.07960842335815276],
        [51.500682590533934, -0.07699015955470061],
        [51.49969229841758, -0.07522506376713341],
        [51.49957185003282, -0.07502073129530179],
        [51.49887151594195, -0.07471132905867654],
        [51.49870661843615, -0.0734986362265487],
        [51.49777785574786, -0.0726835557108687],
        [51.49770364871395, -0.07247017550346165],
        [51.497258499289245, -0.07165982339429493],
        [51.49715973227731, -0.07143459780890547],
        [51.49693670099102, -0.07099580341260568],
        [51.49591130687561, -0.06885229439485606],
        [51.4916728723977, -0.059552061746235535],
      ];
      
  
      const routeLine = L.polyline(routeCoordinates, {
        color: 'red',
        weight: 4,
        opacity: 0.7,
      }).addTo(map);
  
      return () => {
        map.removeLayer(routeLine);
      };
    }, [map]);
  
    return null;
  };

  const isValidCoordinate = (lat, lng) => {
    return typeof lat === 'number' && !isNaN(lat) &&
           typeof lng === 'number' && !isNaN(lng) &&
           lat >= -90 && lat <= 90 &&
           lng >= -180 && lng <= 180;
  };

  return (
    <div className='relative'>
      {/* <div className="flex flex-col p-4 md:flex-row items-center justify-between w-full space-y-4 md:space-y-0">
        <div className="flex flex-col md:flex-row md:space-x-2 w-full md:w-auto">
          <label htmlFor="searchInput" className="sr-only">Search for a location</label>
          <input
            id="searchInput"
            type="text"
            placeholder="Drinking somewhere?"
            value={searchInput}
            onKeyDown={handleKeyDown}
            onChange={handleSearchInputChange}
            className="p-2 rounded border border-gray-300 w-full md:w-auto"
            style={{ maxWidth: '600px' }}
            aria-label="Search for a location"
          />
          <button
            onClick={handleSearch}
            className="p-3 bg-green-600 text-white text-xs font-bold rounded mt-2 md:mt-0 w-full md:w-auto"
            aria-label="Search for entered location"
          >
            Search
          </button>
        </div>
      </div> */}
      <div className="relative" style={{ zIndex: 0 }}>
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: '800px', width: '100%' }}
          ref={mapRef}
          aria-label="Map of pubs with cheapest pints"
        >
            <LocationButton />
            <BeerMileRoute /> 
            <Filters onFilterChange={handleFilterChange} />

          <TileLayer
            url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            id="mapbox/streets-v11"
            tileSize={512}
            zoomOffset={-1}
          />
          <MapContent setMapCenter={setMapCenter} setMapZoom={setMapZoom} />
          {userLocation && isValidCoordinate(userLocation[0], userLocation[1]) && (
            <Marker
              position={userLocation}
              icon={createCustomIcon(blueDotSvgIcon)}
              aria-label="Your location"
            />
          )}
          <MarkerClusterGroup
            disableClusteringAtZoom={14}
            maxClusterRadius={60}
            chunkedLoading
            iconCreateFunction={(cluster) => {
              const childCount = cluster.getChildCount();
              let clusterIcon = greenSvgIcon;
              if (childCount > 10) clusterIcon = orangeSvgIcon;
              if (childCount > 20) clusterIcon = redSvgIcon;
              return L.divIcon({
                html: `<div><span>🍺 &nbsp;${childCount}</span></div>`,
                className: 'custom-marker-cluster',
                iconSize: L.point(70, 40),
                iconUrl: clusterIcon,
              });
            }}
          >
            {pubs.filter(pub => isValidCoordinate(pub.lat, pub.lng)).map((pub, index) => {
              const count = pub.pints.length;
              let markerIconUrl;

              if (selectedMarker === pub.pub) {
                markerIconUrl = selectedPubSvgIcon;
              } else if (shouldHaveStar(pub.pints)) {
                if (pub.price < 6) {
                  markerIconUrl = greenStarSvgIcon;
                } else if (pub.price >= 6 && pub.price < 7) {
                  markerIconUrl = orangeStarSvgIcon;
                } else {
                  markerIconUrl = redStarSvgIcon;
                }
              } else {
                if (pub.price < 6) {
                  markerIconUrl = greenSvgIcon;
                } else if (pub.price >= 6 && pub.price < 7) {
                  markerIconUrl = orangeSvgIcon;
                } else {
                  markerIconUrl = redSvgIcon;
                }
              }

              return (
                <Marker
                  key={`${pub.pub}_${pub.beerType}`}
                  position={[pub.lat, pub.lng]}
                  icon={createCustomIcon(markerIconUrl)}
                  eventHandlers={{
                    click: () => {
                      setSelectedPub({ pub, count });
                      setIsBottomSheetOpen(true);
                      setSelectedMarker(pub.pub);
                    },
                  }}
                  aria-label={`${pub.pub} - Cheapest pint: £${pub.price}`}
                />
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
      <MapLegend />
      {selectedPub && (
        <BottomSheet
          pub={selectedPub.pub}
          count={selectedPub.count}
          isOpen={isBottomSheetOpen}
          onClose={() => {
            setIsBottomSheetOpen(false);
            setSelectedPub(null);
            setSelectedMarker(null);
          }}
        />
      )}
    </div>
  );
};

export default CheapestPintMap;