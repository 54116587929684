import React, { useState, useEffect } from "react";
import Downshift from "downshift";
import axios from "axios";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
const MAPBOX_API_ENDPOINT = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const PubsSelect = ({ selectedPub, onChange }) => {
  const [pubOptions, setPubOptions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(true);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          // console.error("Error getting user's location:", error);
          setLocationPermissionGranted(false);
        }
      );
    } else {
      // console.error("Geolocation is not available in this browser.");
      setLocationPermissionGranted(false);
    }
  }, []);

  const fetchDefaultPubs = async (latitude, longitude) => {
    const radiusInKilometers = 1;
    const earthRadiusInKm = 6371;
    const latDistance = (radiusInKilometers / earthRadiusInKm) * (180 / Math.PI);
    const lonDistance = (radiusInKilometers / earthRadiusInKm) * (180 / Math.PI) / Math.cos((latitude * Math.PI) / 180);
  
    const minLat = latitude - latDistance;
    const maxLat = latitude + latDistance;
    const minLon = longitude - lonDistance;
    const maxLon = longitude + lonDistance;
  
    try {
      const response = await axios.get(
        `${MAPBOX_API_ENDPOINT}(pub, bar).json`,
        {
          params: {
            access_token: MAPBOX_API_KEY,
            country: "GB",
            types: "poi",
            proximity: `${longitude},${latitude}`,
            bbox: `${minLon},${minLat},${maxLon},${maxLat}`,
            limit: 10, // Ensure limit is set here
          },
        }
      );
  
      if (response.status === 200) {
        const options = response.data.features.map((pub) => {
          const placeNameParts = pub.place_name.split(', ');
          const pubNameAndRoad = placeNameParts.slice(0, 2);
          const town = placeNameParts.length > 2 ? placeNameParts[2] : '';
          const postcode = placeNameParts.length > 3 ? placeNameParts[3] : ''; // Extract postcode

  
          // Remove full stops from street names
          const roadName = pubNameAndRoad[1].replace(/\./g, '');
  
          return {
            value: pub.id,
            label: placeNameParts[0],
            pubName: pubNameAndRoad[0],
            roadName,
            town,
            postcode,
            isLabel: false,
          };
        });
  
        setPubOptions([
          {
            label: "Nearby pubs and bars",
            isLabel: true,
          },
          ...options,
        ]);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  
  const handleSearch = async (inputValue) => {
    try {
      const response = await axios.get(
        `${MAPBOX_API_ENDPOINT}${encodeURIComponent(inputValue)}.json`,
        {
          params: {
            access_token: MAPBOX_API_KEY,
            country: "GB",
            types: "poi",
            limit: 10, // Ensure limit is set here
          },
        }
      );
  
      if (response.status === 200) {
        const options = response.data.features.map((pub) => {
          const placeNameParts = pub.place_name.split(', ');
          const pubNameAndRoad = placeNameParts.slice(0, 2);
          const town = placeNameParts.length > 2 ? placeNameParts[2] : '';
          const postcode = placeNameParts.length > 3 ? placeNameParts[3] : ''; // Extract postcode

  
          if (pubNameAndRoad.length >= 2) {
            return {
              value: pub.id,
              label: placeNameParts[0],
              pubName: pubNameAndRoad[0],
              roadName: pubNameAndRoad[1],
              town,
              postcode, // Include postcode here
              isLabel: false,
            };
          }
          return {
            value: pub.id,
            label: placeNameParts[0],
            pubName: pubNameAndRoad[0],
            roadName: "",
            town,
            postcode, // Include postcode here
            isLabel: false,
          };
        });
  
        setPubOptions(options);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  
  return (
      <Downshift
        selectedItem={selectedPub}
        onChange={onChange}
        itemToString={(item) => 
          item ? 
            `${item.pubName}${item.roadName ? `, ${item.roadName}` : ''}${item.town ? `, ${item.town}` : ''}` 
            : ''
        }
                >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          openMenu,
          closeMenu,
        }) => (
          <div className="relative">
            <input
              {...getInputProps({
                placeholder: "Select to see nearby or start typing...",
                onFocus: () => {
                  if (!userLocation) {
                    return;
                  }
                  fetchDefaultPubs(userLocation.latitude, userLocation.longitude);
                  openMenu();
                },
                onChange: (e) => handleSearch(e.target.value),
              })}
              className="w-full border rounded px-2 py-2"
            />
    
            <div
              {...getMenuProps()}
              className={`absolute z-10 mt-2 w-full ${
                isOpen && pubOptions.length > 0 ? "block" : "hidden"
              } bg-white border rounded shadow-md`}
            >
            {pubOptions.map((item, index) => (
                <div
                    {...getItemProps({
                        key: item.value || index,
                        index,
                        item,
                        disabled: item.isLabel,
                    })}
                    className={`${
                        highlightedIndex === index ? "bg-blue-100" : ""
                    } py-3 px-3 cursor-pointer ${
                        item.isLabel ? "text-sm text-slate-700 select-none" : ""
                    }`}
                >
                    {item.isLabel ? "Nearby pubs and bars" : `${item.pubName}, ${item.roadName}${item.town ? `, ${item.town}` : ''}${item.postcode ? `, ${item.postcode}` : ''}`}
                </div>
            ))}
            </div>
          </div>
        )}
      </Downshift>
    
  );
};

export default PubsSelect;