// src/utils/cookieManagement.js
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

export const useCookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);

  const isConsentGiven = () => {
    return cookies.cookieConsent === 'accepted_all' || cookies.cookieConsent === 'accepted_essential';
  };

  const isAllCookiesAccepted = () => {
    return cookies.cookieConsent === 'accepted_all';
  };

  const hasAnalyticsConsent = () => {
    return cookies.cookieConsent === 'accepted_all'; // Customize as needed
  };

  const hasMonetagConsent = () => {
    return cookies.cookieConsent === 'accepted_all'; // Customize as needed
  };

  const loadGoogleAnalytics = () => {
    if (window.gtag) return; // Prevent loading multiple times

    // Initialize dataLayer if not already defined
    window.dataLayer = window.dataLayer || [];

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=YOUR_GA_TRACKING_ID`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'YOUR_GA_TRACKING_ID');
    };
  };

  const unloadGoogleAnalytics = () => {
    const scripts = document.querySelectorAll('script[src*="googletagmanager.com"]');
    scripts.forEach(script => script.remove());
    // Clear dataLayer if needed
    window.dataLayer = [];
  };

  const loadMonetag = () => {
    if (window.monetag) return; // Prevent loading multiple times

    const script = document.createElement('script');
    script.src = `https://example.monetag.com/script.js`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.monetag && window.monetag.init(); // Adjust based on Monetag API
    };
  };

  const unloadMonetag = () => {
    const scripts = document.querySelectorAll('script[src*="monetag.com"]');
    scripts.forEach(script => script.remove());
  };

  const manageCookies = () => {
    if (isConsentGiven()) {
      if (hasAnalyticsConsent()) {
        loadGoogleAnalytics();
      } else {
        unloadGoogleAnalytics();
      }
      if (hasMonetagConsent()) {
        loadMonetag();
      } else {
        unloadMonetag();
      }
    } else {
      unloadGoogleAnalytics();
      unloadMonetag();
    }
  };

  useEffect(() => {
    manageCookies();
  }, [cookies.cookieConsent]);

  return { isConsentGiven, isAllCookiesAccepted, manageCookies };
};
