import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { getDatabase, ref, query, orderByChild, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { format, parseISO, startOfMonth, addMonths, subYears, isAfter, isBefore, min } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "average-pint-london.firebaseapp.com",
    databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "average-pint-london",
    storageBucket: "average-pint-london.appspot.com",
    messagingSenderId: "284009350282",
    appId: "1:284009350282:web:ceec7375a8a98f60e81973",
    measurementId: "G-3DE63Z7MQE"
  };

const AveragePintChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Average Pint Price',
        data: [],
        borderColor: '#16a349',
        backgroundColor: 'rgba(22, 163, 73, 0.2)',
        tension: 0.3,
        fill: true,
        pointRadius: 4,
        pointBackgroundColor: '#16a349',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: '#16a349',
        pointHoverBorderColor: '#ffffff',
        pointHoverBorderWidth: 2
      }
    ]
  });
  const [viewMode, setViewMode] = useState('month');
  const [allPrices, setAllPrices] = useState([]);

  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp);
    const pricesRef = ref(database, "beerPrices");
    const pricesQuery = query(pricesRef, orderByChild("timestamp"));

    const unsubscribe = onValue(pricesQuery, (snapshot) => {
      const prices = [];
      snapshot.forEach((childSnapshot) => {
        const price = childSnapshot.val();
        if (price.price && price.timestamp) {
          const date = parseISO(price.timestamp);
          prices.push({
            price: price.price,
            timestamp: date
          });
        }
      });

      prices.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      setAllPrices(prices);
      updateChartData(prices, viewMode);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    updateChartData(allPrices, viewMode);
  }, [viewMode, allPrices]);

  const updateChartData = (prices, mode) => {
    const currentDate = new Date();
    let startDate, groupingFormat, labelFormat;

    const earliestDate = prices.length > 0 ? prices[0].timestamp : currentDate;
    const september2023 = new Date(2023, 8, 1); // September is month 8 (0-indexed)

    switch (mode) {
      case 'month':
        startDate = startOfMonth(min([earliestDate, september2023]));
        groupingFormat = 'yyyy-MM';
        labelFormat = 'MMM yyyy';
        break;
      case 'year':
        startDate = startOfMonth(subYears(currentDate, 4)); // Show last 5 years
        groupingFormat = 'yyyy';
        labelFormat = 'yyyy';
        break;
    }

    const filteredPrices = prices.filter(price => 
      isAfter(price.timestamp, startDate) || price.timestamp.getTime() === startDate.getTime()
    );

    const groupedAverages = filteredPrices.reduce((acc, { price, timestamp }) => {
      const key = format(timestamp, groupingFormat);
      if (!acc[key]) {
        acc[key] = { sum: 0, count: 0 };
      }
      acc[key].sum += price;
      acc[key].count += 1;
      return acc;
    }, {});

    const labels = [];
    const data = [];

    let currentPeriod = startDate;
    while (isBefore(currentPeriod, currentDate) || currentPeriod.getMonth() === currentDate.getMonth()) {
      const key = format(currentPeriod, groupingFormat);
      labels.push(format(currentPeriod, labelFormat));
      
      if (groupedAverages[key]) {
        const average = groupedAverages[key].sum / groupedAverages[key].count;
        data.push(average);
      } else {
        data.push(null);
      }
      
      currentPeriod = addMonths(currentPeriod, 1);
    }

    setChartData({
      labels,
      datasets: [
        {
          ...chartData.datasets[0],
          data
        }
      ]
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => `Average Price: £${context.parsed.y.toFixed(2)}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (£)'
        },
        ticks: {
          callback: (value) => `£${value.toFixed(2)}`
        }
      },
      x: {
        title: {
          display: false,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };

  return (
    <div className="w-full mb-8 bg-white border rounded-lg border-gray-200">
      <div className="p-4">
      <div className="flex sm:flex-row flex-col sm:gap-4 justify-between mb-4">
        <h2 className="text-xl font-bold mb-4">Average pint price over time</h2>
        <div>
          <button
            className={`text-sm px-2 py-2 rounded-l-lg ${viewMode === 'month' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setViewMode('month')}
          >
            Month
          </button>
          <button
            className={`text-sm px-2 py-2 rounded-r-lg ${viewMode === 'year' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setViewMode('year')}
          >
            Year
          </button>
          </div>
        </div>
      </div>
      <div className="w-full h-64 sm:h-96">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export default AveragePintChart;