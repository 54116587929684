import React, { createContext, useState, useContext, useEffect } from 'react';

const PintContext = createContext();

export const usePintContext = () => useContext(PintContext);

const getLevelInfo = (pintCount) => {
  const levels = [
    { threshold: 0, name: "1 - What's beer? 🤷‍♂️" },
    { threshold: 3, name: "2 - Beginner brew 🟩" },
    { threshold: 5, name: "3 - Pint enthusiast 🍺" },
    { threshold: 10, name: "4 - Pub regular 🍻" },
    { threshold: 20, name: "5 - Local legend 🐉" },
    { threshold: 30, name: "6 - Pints are life 🛟 ✨" }
  ];
  
  let currentLevel = levels[0];
  
  for (let i = 0; i < levels.length; i++) {
    if (pintCount >= levels[i].threshold) {
      currentLevel = levels[i];
    } else {
      break;
    }
  }
  
  return currentLevel;
};

export const PintProvider = ({ children }) => {
  const [reportedPints, setReportedPints] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(getLevelInfo(0));

  useEffect(() => {
    const storedPints = localStorage.getItem('reportedPints');
    if (storedPints) {
      const pintCount = parseInt(storedPints, 10);
      setReportedPints(pintCount);
      setCurrentLevel(getLevelInfo(pintCount));
    }
  }, []);

  const updateReportedPints = (count) => {
    setReportedPints(count);
    setCurrentLevel(getLevelInfo(count));
    localStorage.setItem('reportedPints', count.toString());
  };

  return (
    <PintContext.Provider value={{ reportedPints, currentLevel, updateReportedPints }}>
      {children}
    </PintContext.Provider>
  );
};